import React from 'react';
import { Avatar, Select } from 'antd';

import { FormFieldInputWrapperClasses, FormFieldWrapperClasses } from '../';
import { accountsColorObject, trb } from 'src/Utilities';
import { formFieldId } from 'src/utils';

const { Option } = Select;

export class SelectFormField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: !this.props.input.value && this.props.input.value !== 0 && !this.props.defaultValue && this.props.defaultValue !== 0,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.state !== false &&
      ((this.props.input.value && this.props.input.value !== prevProps.input.value) || (this.props.defaultValue && this.props.defaultValue !== prevProps.defaultValue))
    )
      this.setState({
        active: false,
      });
  };

  onBlur = (e) => {
    if (!this.props.input.value && this.props.input.value !== 0)
      this.setState({
        active: true,
      });
    if (e) this.props.input.onBlur(e);
  };

  onSelect = () => {
    this.setState({
      active: false,
    });
  };

  onValueChange = (value) => {
    this.props.input.onChange(value);
    this.props?.helperOnChange?.(value);
  };

  render = () => {
    const {
        title,
        allowClear,
        titleWithoutTranslate,
        className,
        classNameField,
        classNameTitle,
        classNameError,
        inline,
        size,
        placeholder,
        disabled,
        options,
        optionsLabel,
        optionsDisabled,
        optionsValue,
        translate,
        meta,
        small,
        optionsColor,
        defaultValue,
      } = this.props,
      { active } = this.state;

    const checkOption = (value, input) => {
        if (!options) {
          return null;
        }

        const optionValue = options.find((e) => e[optionsValue] === value);

        if (optionValue) {
          return value;
        } else {
          input.onChange(undefined);
        }
      },
      renderOptions = (options) => {
        return options.map((item, index) => (
          <Option
            id={formFieldId(this.props.input.name, `SelectOption${index}`)}
            disabled={item[optionsDisabled]}
            value={item[optionsValue]}
            key={item?.key ?? `${item[optionsValue]}-${index}`}
          >
            {optionsColor && !item[optionsDisabled] && (
              <Avatar
                // size={17}
                style={{
                  backgroundColor: item[optionsColor] ? accountsColorObject[item[optionsColor]] : accountsColorObject[1],
                  // marginRight: 5,
                  // marginBottom: 5,
                }}
              />
            )}
            {translate ? trb(translate + item[optionsLabel]) : item[optionsLabel]}
          </Option>
        ));
      };

    return (
      <div id={formFieldId(this.props.input.name, 'SelectWrapper')} className={`${FormFieldWrapperClasses(className, inline, disabled, active, small)} select-field-wrapper`}>
        {title && (
          <div
            className={
              classNameTitle
                ? 'form-field-title ' + classNameTitle
                : inline
                ? (className && className.indexOf('col-12') === -1) || (className && className.indexOf('col-12') !== -1 && className.indexOf('col-md-') !== -1)
                  ? 'form-field-title col-12 col-md-5 text-left pb-2 pb-md-0 pr-md-0'
                  : 'form-field-title col-12 col-md-3 text-left text-md-right pb-2 pb-md-0'
                : 'form-field-title col-12'
            }
          >
            {titleWithoutTranslate ? title : trb(title)}
          </div>
        )}
        <div className={classNameField ? 'form-field-item ' + classNameField : FormFieldInputWrapperClasses(meta.error, meta.submitError, meta.touched, inline, className)}>
          <Select
            allowClear={allowClear}
            placeholder={trb(placeholder)}
            disabled={disabled}
            name={this.props.input.name}
            value={checkOption(this.props.input.value, this.props.input)}
            onChange={this.onValueChange}
            size={size}
            onBlur={this.onBlur}
            onSelect={this.onSelect}
            defaultActiveFirstOption={false}
            defaultValue={defaultValue}
            style={{ width: '100%' }}
            id={formFieldId(this.props.input.name, 'Select')}
          >
            {options && renderOptions(options)}
          </Select>
        </div>
        <div id={formFieldId(this.props.input.name, 'SelectError')} className={classNameError ? 'form-field-error ' + classNameError : inline ? 'form-field-error col-12 col-md-3' : 'form-field-error col-12'}>
          {meta.touched && (meta.error || meta.submitError)}
        </div>
      </div>
    );
  };
}
