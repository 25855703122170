import React from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Input, Progress, Result } from 'antd';
import { Form, Field } from 'react-final-form';

import { i18, requiredApproveCode, trb, numberFormat, translationGroups, SIGN_CANCEL_TYPES } from '../../../Utilities';
import { Number } from '../../../Components';
import { textColors } from '../../../Config/index.js';

const ButtonGroup = Button.Group;

class OperationApprovalSMS extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sceconds: 90,
      secondsRemaining: null,
      secondsEnd: false,
    };

    this._ref = React.createRef();

    this.tick = this.tick.bind(this);
  }

  scrollToMyRef = () => this._ref.current.scrollIntoView(false);

  componentDidMount() {
    if (this.props.errorCode) {
      return;
    }
    this.scrollToMyRef();
    this.setState((prevState) => ({ secondsRemaining: prevState.sceconds }));
    this.interval = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    if (!this.props.dontScrollToTop) {
      window.scrollTo(0, 0);
    }
  }

  tick() {
    this.setState((prevState) => ({ secondsRemaining: prevState.secondsRemaining - 1 }));
    if (this.state.secondsRemaining <= 0) {
      this.setState({ secondsEnd: true });
      this.setState({ secondsRemaining: <CloseOutlined /> });
      clearInterval(this.interval);
      this.props.cancel(SIGN_CANCEL_TYPES.TIMEOUT);
    }
  }

  submit = (values) => {
    this.props.submit(values);
  };

  render() {
    const { approveSum, horizontal, approveCount, errorCode } = this.props;

    if (errorCode) {
      return (
        <>
          {errorCode && (
            <Result
              status="error"
              title={trb(translationGroups.ErrorTitles + errorCode)}
              subTitle={trb(translationGroups.ErrorDescriptions + errorCode)}
            />
          )}
        </>
      );
    }

    return (
      <Form
        onSubmit={this.submit}
        initialValues={{
          SMS_Code: this.props.code,
          RQ_ID: this.props.RQ_ID,
        }}
        render={({ handleSubmit }) => {
          return (
            <form
              id="OperationApprovalSMSForm"
              className={horizontal ? 'd-flex flex-row justify-content-around align-items-center' : null}
              style={horizontal ? { maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' } : null}
              onSubmit={handleSubmit}
            >
              <div
                ref={this._ref}
                style={horizontal ? null : { textAlign: 'center' }}
                className={horizontal ? 'd-flex flex-row align-items-center' : null}
              >
                <Progress
                  type="circle"
                  percent={(100 / this.state.sceconds) * this.state.secondsRemaining}
                  status={this.state.secondsEnd ? 'exception' : 'active'}
                  format={() => (!this.state.secondsEnd ? Math.round(this.state.secondsRemaining) + trb(i18.Labels.SecondsShort) : this.state.secondsRemaining)}
                  size={100}
                  style={{ marginBottom: '24px' }}
                  strokeColor={textColors.primary}
                />
                <div className={horizontal ? 'd-flex flex-column text-left p-3' : null}>
                  {approveCount && (
                    <div>
                      {trb(i18.Labels.ApprovalPaymentsCount)}:{' '}
                      <Number
                        value={approveCount}
                        format={numberFormat().number}
                        text={1}
                      />
                    </div>
                  )}
                  {approveSum && approveSum.sum && (
                    <div>
                      {trb(i18.Labels.ApprovalSum)}: {approveSum.sum} {approveSum.currency}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={horizontal ? 'mb-2' : 'mb-3 text-center'}>{trb(i18.Messages.FillYourSMSCodeToApproveOperation)}</div>
                <div style={{ textAlign: 'center', marginBottom: '24px' }}>
                  <Field
                    name="SMS_Code"
                    validate={requiredApproveCode}
                  >
                    {({ input, meta }) => (
                      <div>
                        <Input
                          id="OperationApprovalSMSInput"
                          style={{ width: '100px', textAlign: 'center' }}
                          className="eb_fw_600"
                          placeholder="----"
                          autoFocus={true}
                          maxLength={4}
                          onChange={input.onChange}
                        />
                        {meta.error && meta.touched && (
                          <div
                            id="OperationApprovalSMSInputError"
                            className="eb_fs_13 text-error"
                          >
                            {meta.error}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div style={{ textAlign: 'center', marginBottom: '24px' }}>
                  <ButtonGroup>
                    <Button
                      id="OperationApprovalSMSCancelButton"
                      onClick={() => {
                        if (this.props.send) {
                          return;
                        }
                        this.props.cancel(SIGN_CANCEL_TYPES.MANUAL);
                      }}
                      disabled={this.props.send}
                    >
                      <CloseOutlined />
                      {trb(i18.Buttons.Cancel)}
                    </Button>
                    {this.state.secondsEnd ? (
                      <Button
                        id="OperationApprovalSMSResendButton"
                        type="primary"
                        onClick={this.resend}
                      >
                        <SyncOutlined />
                        {trb(i18.Buttons.Resend)}
                      </Button>
                    ) : (
                      <Button
                        id="OperationApprovalSMSSubmitButton"
                        type="primary"
                        htmlType="submit"
                        disabled={this.props.send}
                        loading={this.props.send}
                      >
                        {!this.props.send && <CheckOutlined />}
                        {trb(i18.Buttons.Approve)}
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export { OperationApprovalSMS };

OperationApprovalSMS.propTypes = {
  cancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  resend: PropTypes.func.isRequired,
};
