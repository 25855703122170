import React, { useEffect, useState } from 'react';
import { Container, ContentLoader, ContentNoData, Notification } from 'src/Components';
import { Steps } from 'antd';
import { PaymentPreview, PaymentUnsuccess, PaymentWaiting, ViewPaymentsInternational } from 'src/Containers';
import { useDispatch, useSelector } from 'react-redux';
import { CustomIcon, i18, logError, Payment, SIGN_CANCEL_TYPES, Sleep, throwError, translationGroups, trb } from 'src/Utilities';
import { clearValues, FeeActions, PMCActions } from 'src/Redux';

export const PaymentInternational = () => {
  const dispatch = useDispatch();
  const { sendPTR, PTR, PVR, PayPersonTypes, sendPayPersonTypes, sendCheckIBAN, sendGetFxRate, forexPayments, forexPaymentsSend } = useSelector((state) => state.PMCReducers);
  const { sendFeeList, feeList } = useSelector((state) => state.FeeReducers);
  const { MALR, sendMALR } = useSelector((state) => state.UACReducers);
  const { language } = useSelector((state) => state.languageReducer);

  const paymentType = Payment.INTERNATIONAL;

  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submittingSave, setSubmittingSave] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [saveSign, setSaveSign] = useState(false);
  const [withError, setWithError] = useState(null);
  const [approve, setApprove] = useState(null);
  const [data, setData] = useState(null);
  const [RQ_ID, setRQID] = useState(null);
  const [AuthType, setAuthType] = useState(null);
  const [Code, setCode] = useState(null);

  useEffect(() => {
    const componentMounted = async () => {
      let lng = language.split('-')[0];

      await dispatch(PMCActions.getPayPersonTypes({ language: lng }));

      if (paymentType === Payment.FEE && !sendFeeList && !feeList) {
        await dispatch(FeeActions.getFeeList());
      }

      localStorage.removeItem('SavedIBAN');
      localStorage.removeItem('SavedCurrency');

      await dispatch(PMCActions.clearCheckIBANMember());
      await dispatch(PMCActions.clearHistoricalPayments());
      await dispatch(PMCActions.clearPVR());
      await dispatch(clearValues());

      // duplicate call
      // await dispatch(PMCActions.getForexPayments());
    };
    componentMounted();
    window.history.replaceState({}, document.title);
  }, []);

  useEffect(() => {
    resetPayment();

    const paymentTypeChanged = async () => {
      setData(null);
      setErrors(null);
      setCurrentStep(0);
      setSaveSign(false);

      localStorage.removeItem('SavedIBAN');
      localStorage.removeItem('SavedCurrency');

      await dispatch(PMCActions.clearCheckIBANMember());
      await dispatch(PMCActions.clearHistoricalPayments());
      await dispatch(PMCActions.clearPVR());

      if (paymentType === Payment.FEE && !sendFeeList && !feeList) {
        await dispatch(FeeActions.getFeeList());
      }
    };
    if (paymentType) {
      paymentTypeChanged();
    }
  }, [paymentType]);

  const edit = () => {
    setErrors(null);
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const submit = async (values) => {
    setErrors(null);
    setSubmitting(true);
    setData(values);

    try {
      const response = await dispatch(PMCActions.postPVR(values));
      setSubmitting(false);

      if (response?.payload?.IsSuccessfull) {
        setCurrentStep((prevStep) => prevStep + 1);
      } else if (response?.payload?.ErrorCaptions) {
        setErrors(response?.payload?.ErrorCaptions);
      } else {
        logError(response);
      }
    } catch (error) {
      logError(error);
    }
  };

  const submitPreview = async () => {
    setErrors(null);
    setSubmitting(true);
    setWithError(false);

    const response = await dispatch(PMCActions.postAPR({ Payment: data.Payment, Language: language }));

    if (response.payload?.IsSuccessfull) {
      if (response.payload.RQ_ID !== '') {
        setApprove(true);
        setRQID(response.payload.RQ_ID);
        setAuthType(response.payload.AuthType);
        setCode(response.payload.ControlCode);
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    } else {
      setErrors(response?.payload?.PaymentErrors?.filter((items, index) => index === response?.payload?.PaymentErrors.findIndex((item) => item.ErrorCode === items.ErrorCode)));
    }

    setSubmitting(false);

    if (response?.payload?.RQ_ID === '') {
      await Sleep(1000);
      await dispatch(PMCActions.postPTR());
    }
  };

  const cancelApprove = (type) => {
    setSubmitting(false);
    setApprove(false);
    setRQID(null);
    setAuthType(null);
    setCode(null);
    Notification({
      type: 'warning',
      message: i18.NotificationTitles.PaymentCanceled,
      description:
        i18.NotificationMessages[
          type === SIGN_CANCEL_TYPES.TIMEOUT ? 'PaymentCanceledTimeout' : type === SIGN_CANCEL_TYPES.MANUAL ? 'PaymentCanceledManual' : 'PaymentCanceledRejected'
        ],
    });
  };

  const successApprove = async () => {
    setApprove(false);
    setCurrentStep((prevStep) => prevStep + 1);
    setWithError(false);
  };

  const waitingApprove = async () => {
    setApprove(false);
    setWithError('waiting');
    setCurrentStep((prevStep) => prevStep + 1);
    Notification({
      type: 'info',
      message: i18.NotificationTitles.NeedCheckLater,
      description: i18.NotificationMessages.NeedCheckLater,
    });
  };

  const unsuccessApprove = async (err) => {
    cancelApprove(SIGN_CANCEL_TYPES.REJECTED);
    /* setApprove(false);
    setWithError(err);
    setCurrentStep((prevStep) => prevStep + 1);
    Notification({
      type: 'error',
      message: i18.NotificationTitles.IsUnsuccessfull,
      description: translationGroups.ErrorCode + err,
    }); */
  };

  const BadApproveCode = () => {
    setSubmitting(false);
    setApprove(false);
    setRQID(null);
    setAuthType(null);
    setCode(null);
    Notification({
      type: 'error',
      message: i18.NotificationTitles.BadApproveCode,
      description: i18.NotificationMessages.BadApproveCode,
    });
  };

  const resetPayment = async () => {
    dispatch(PMCActions.postPTR());
    setSubmitting(false);
    setSubmittingSave(false);
    setCurrentStep(0);
    setData(null);

    setApprove(false);
    setErrors(null);
    setSaveSign(false);

    localStorage.removeItem('SavedIBAN');
    localStorage.removeItem('SavedCurrency');

    await dispatch(PMCActions.clearCheckIBANMember());
    await dispatch(PMCActions.clearHistoricalPayments());
    await dispatch(PMCActions.clearPVR());
    dispatch(PMCActions.getForexPayments());
  };

  const paymentSaveToSign = () => {
    setErrors(null);
    setSubmittingSave(true);

    dispatch(PMCActions.postSPR(data)).then((response) => {
      if (response?.payload?.IsSuccessfull) {
        setSubmittingSave(false);
        setSaveSign(true);
      } else {
        setErrors(response?.payload?.ErrorCaptions || null);
        setSubmittingSave(false);
      }
    });
  };

  const submitSmsApprove = (values) => {
    dispatch(PMCActions.postAPSMSR(values)).then((response) => {
      if (response?.payload?.IsSuccessfull === true) {
        successApprove();
      } else if (response?.payload?.IsSuccessfull === false && response?.payload?.ErrorCode && response?.payload?.ErrorCode?.toLowerCase() === 'waiting_to_confirm') {
        waitingApprove();
      } else if (response?.payload?.IsSuccessfull === false && response?.payload?.ErrorCode) {
        unsuccessApprove(response.payload.ErrorCode);
      } else {
        BadApproveCode();
      }
    });
  };

  const submitMobileApprove = async () => {
    try {
      const response = await dispatch(PMCActions.postAPESCR({ RQ_ID: RQ_ID }));
      if (response?.payload) {
        if (response.payload.AuthCheckResult === null) {
          return true;
        }
        if (response.payload.AuthCheckResult === false) {
          if (response.payload.IsSuccessfull === false && response.payload.ErrorCode && response.payload.ErrorCode.toLowerCase() == 'waiting_to_confirm') waitingApprove();
          else if (response.payload.ErrorCode) unsuccessApprove(response.payload.ErrorCode);
          else BadApproveCode();
        } else if (response.payload.AuthCheckResult === true && response.payload.IsSuccessfull) {
          successApprove();
        }
      } else {
        throwError(response, 'submitMobileApprove');
      }
      return false;
    } catch (error) {
      logError(error);
      return false;
    }
  };

  if (currentStep === 0 && forexPaymentsSend) {
    return <ContentLoader />;
  }

  if ((sendPTR && currentStep !== 2) || sendFeeList || sendMALR || sendPayPersonTypes) {
    return <ContentLoader />;
  }

  if ((!PTR && currentStep !== 2) || !MALR) {
    return <ContentNoData />;
  }

  return (
    <>
      <div className="eb_content_subtitle">{trb(i18.ContentTitles.InternationalPayment)}</div>
      <Container
        wide
        gradient={currentStep === 2}
      >
        <Steps
          direction="horizontal"
          current={currentStep}
          className={`mb-3 mb-md-2 eb_steps`}
        >
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentFirstStep"
              />
            }
          />
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentSecondStep"
              />
            }
          />
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentThirdStep"
              />
            }
          />
        </Steps>
        <div className="position-relative w-100">
          {currentStep === 0 && (
            <div>
              {(sendCheckIBAN || sendGetFxRate) && <ContentLoader hover />}
              <ViewPaymentsInternational
                data={data}
                submit={submit}
                errorsPVR={errors}
              />
            </div>
          )}
          {currentStep === 1 && (
            <PaymentPreview
              paymentType={Payment.INTERNATIONAL}
              data={data}
              PTR={PTR}
              PVR={PVR}
              PayPersonTypes={PayPersonTypes}
              edit={edit}
              submit={submitting ? () => {} : submitPreview}
              submitting={submitting}
              approve={approve}
              AuthType={AuthType}
              RQ_ID={RQ_ID}
              Code={Code}
              cancelApprove={cancelApprove}
              successApprove={successApprove}
              submitSmsApprove={submitSmsApprove}
              submitMobileApprove={submitMobileApprove}
              paymentSaveToSign={paymentSaveToSign}
              errors={errors}
              saveSign={saveSign}
              resetPayment={resetPayment}
              forexPayments={forexPayments}
              submittingSave={submittingSave}
            />
          )}
          {currentStep === 2 && (
            <>
              {withError && (
                <>
                  {withError === 'waiting' && <PaymentWaiting resetPayment={resetPayment} />}
                  {withError !== 'waiting' && (
                    <PaymentUnsuccess
                      resetPayment={resetPayment}
                      error={withError}
                    />
                  )}
                </>
              )}
              {!withError && (
                <PaymentPreview
                  paymentType={Payment.INTERNATIONAL}
                  data={data}
                  PTR={PTR}
                  PVR={PVR}
                  PayPersonTypes={PayPersonTypes}
                  edit={edit}
                  submit={submitting ? () => {} : submitPreview}
                  submitting={submitting}
                  success={true}
                  resetPayment={resetPayment}
                />
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
};

