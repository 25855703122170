import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';

import { checkJsonProp, i18, trb } from 'src/Utilities';

export const AccountsManagementSpy = ({ data, permissions, form, values, editing }) => {
  const [currentAccounts, setCurrentAccounts] = useState(null);

  const sortByProducttype = (accounts) => {
    return accounts?.sort((a, b) => {
      return +a.ProductType - +b.ProductType;
    });
  };
  useEffect(() => {
    const ClientPermissions = permissions?.find((e) => e.ID === data.ClientID)?.PermissionsList;
    form.reset({
      ClientID: data.ClientID,
      ClientPermissions,
    });
    setCurrentAccounts(sortByProducttype(ClientPermissions));
  }, [data.ClientID]);

  useEffect(() => {
    setCurrentAccounts(sortByProducttype(data.ClientPermissions));
  }, [data.ClientPermissions]);

  if (!checkJsonProp(data, 'ClientID')) {
    return null;
  }

  const onChange = (index, value) => {
    const oldValue = values.ClientPermissions[index].Permission;
    const newValue = oldValue !== 0 && oldValue === value ? 0 : value;
    form.change(`ClientPermissions.${index}.Permission`, newValue);
  };

  return (
    <div className="row mx-0 px-0 mb-3 eb_table_wrapper_bottom_lined">
      <div className="col-12 row">
        <div className="col-8">
          <div className="text-black pl-3">{trb(i18.ContentSubTitles.AccountsAndPermissions)}</div>
        </div>
        <div className="d-none d-md-flex col-4 row text-right justify-content-end px-0 mx-0">
          <div className="text-black px-0">{trb(i18.ContentSubTitles.AccountsPermissions)}</div>
        </div>
      </div>
      {currentAccounts?.map((item, index) => (
        <div
          className="col-12 row py-2 border-top mx-0"
          key={`${index}-${item.ContractNumber}`}
        >
          <div className="col-8 pt-2">{item.ContractNumber}</div>
          <div className="col-4 row pt-2 pb-1 text-right justify-content-end px-0 mx-0">
            <div>
              <Switch
                id={`AccountItem${index}Switch`}
                onChange={() => onChange(index, `${item.Permission}` === '3' ? 0 : 3)}
                checked={`${item.Permission}` === '3'}
                disabled={!editing}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
