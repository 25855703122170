import React, { memo, useCallback, useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { CustomIcon, useGetWindowSize, i18, trb } from 'src/Utilities';
import { routerPath } from 'src/Config';
import { UACActiveUser } from 'src/Redux';

export const PartMenu = memo((props) => {
  const { menuCollapsed, contentMenu, contentMenuPath, toggle, toggleMenu, media } = props;
  const { mobileCheck, tabletCheck } = useGetWindowSize();
  const { IsAdmin } = useSelector(UACActiveUser);

  const [current, setCurrent] = useState(useLocation().pathname);
  const [selectedLinks, setSelectedLinks] = useState([]);
  const [mode, setMode] = useState('inline');

  useEffect(() => {
    const newMode = !tabletCheck ? 'inline' : menuCollapsed ? 'inline' : 'horizontal';
    if (newMode !== mode) {
      setMode(newMode);
    }
  }, [tabletCheck, menuCollapsed]);

  const location = useLocation();
  const navigate = useNavigate();
  const isBioFaceOrSMS = useSelector((state) => ['BIOFACE', 'SMS'].includes(state?.UACReducers?.ATR?.AuthType?.toUpperCase()));

  useEffect(() => {
    saveSelectedLinks();
  }, []);

  useEffect(() => {
    if (current !== location.pathname) {
      saveSelectedLinks();
    }
  }, [location.pathname]);

  const saveSelectedLinks = useCallback(() => {
    setCurrent(location.pathname);
    const pathComponents = location.pathname.split('/', 3);
    if (pathComponents.length === 3) {
      setSelectedLinks([location.pathname, pathComponents.slice(0, 2).join('/')]);
    } else {
      setSelectedLinks([location.pathname]);
    }
  }, [location.pathname]);

  const clickMenu = (e) => {
    if (media) {
      toggle();
    }

    if (location.pathname.slice(0, 9) !== e.key) {
      navigate(e.key);
    }

    if (tabletCheck && menuCollapsed) {
      toggleMenu();
    }
  };

  const advancedMenu = [
    {
      title: i18.Menu.Summary,
      icon: 'home',
      type: 'custom',
      path: routerPath.index,
      hidden: mobileCheck,
    },
    {
      title: i18.Menu.Payments,
      icon: 'payment',
      type: 'custom',
      path: routerPath.payments,
      submenu: [
        {
          title: i18.PaymentTypes.OwnAccounts,
          path: routerPath.payments + '/own-accounts',
        },
        {
          title: i18.PaymentTypes.NewPayment,
          path: routerPath.payments + '/new-payment',
        },
        {
          title: i18.PaymentTypes.FeePayment,
          path: routerPath.payments + '/fee-payment',
        },
        {
          title: i18.ContentMenu.NoSignetPayment,
          path: routerPath.unsigned,
        },
        /* TODO: commented out group payments
                {
                    title: i18.ContentMenu.GroupPayment,
                    path: routerPath.paymentGroup
                }, {
                    title: i18.ContentMenu.PaymentGroups,
                    path: routerPath.paymentGroups
                },*/ {
          title: i18.ContentMenu.PaymentTemplates,
          path: routerPath.paymentTemplates,
        },
        {
          title: i18.ContentMenu.PaymentUploading,
          path: routerPath.paymentUploading,
        },
        ...(import.meta.env.VITE_NODE_ENV !== 'prod'
          ? [
              {
                title: i18.ContentMenu.Beneficiaries,
                path: routerPath.beneficiaries,
              },
            ]
          : []),
      ],
    },
    {
      title: i18.Menu.Deposit,
      icon: 'deposit',
      type: 'custom',
      path: routerPath.deposits,
    },
    {
      title: i18.Menu.Invoice,
      icon: 'invoice',
      type: 'custom',
      path: routerPath.invoice,
    },
    {
      title: i18.Menu.Operations,
      icon: 'operations',
      type: 'custom',
      path: routerPath.operations,
    },
    {
      title: i18.Menu.Settings,
      icon: 'settings',
      type: 'custom',
      path: routerPath.settings,
      submenu: [
        {
          title: i18.ContentMenu.ChangePassword,
          path: routerPath.settings + '/change-password',
        },
        {
          title: i18.ContentMenu.BlockCard,
          path: routerPath.settings + '/block-card',
        },
        {
          title: i18.ContentMenu.AccountsLimits,
          path: routerPath.settings + '/accounts-limits',
        },
        {
          ...(IsAdmin
            ? {
                title: i18.ContentMenu.AccountsManagement,
                path: routerPath.settings + '/accounts-management',
              }
            : {}),
        },
      ].filter((menu) => menu.title),
    },
  ];

  /*
  if (isBioFaceOrSMS) {
    const index = advancedMenu.findIndex((item) => item.title === i18.Menu.Deposit);
    if (index !== -1) {
      advancedMenu.splice(index, 1);
    }
  } */

  const Icon = (props) => (
    <CustomIcon
      icon={props.item.icon}
      type={props.item.type}
    />
  );

  const getMenuItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label,
      id: `SubMenuItem${key?.replace(/\//gi, '')}`,
    };
  };

  return (
    <div className="eb_menu">
      <Menu
        id="SidebarMenu"
        onClick={(e) => clickMenu(e)}
        theme="light"
        selectedKeys={selectedLinks}
        openKeys={[contentMenuPath]}
        mode={mode}
        style={{ position: 'relative', border: 'none' }}
        inlineIndent={mobileCheck ? 0 : 32}
        items={advancedMenu
          .filter((item) => !item.hidden)
          .map((menu) => {
            return menu.path?.indexOf(contentMenuPath) > -1 && !tabletCheck && menuCollapsed
              ? getMenuItem(
                  trb(menu.title),
                  menu.path,
                  <Icon item={menu} />,
                  contentMenu?.map((item) => {
                    return getMenuItem(menuCollapsed ? trb(item.title) : '', item.path, menuCollapsed ? '' : <Icon item={item} />);
                  })
                )
              : getMenuItem(!tabletCheck || menuCollapsed ? trb(menu.title) : null, menu.path, <Icon item={menu} />);
          })}
      />
    </div>
  );
});
