import React from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { Alert, Button } from 'antd';
import { isEqual } from 'lodash';

import { DMCActions, PMCActions, UACActions } from '../../../../Redux';
import { i18, required, trb, checkJsonProp } from '../../../../Utilities';
import { FormField, FormFieldType, ContentLoader, ContentNoData, Notification, OperationApproval, Container } from '../../../../Components';
import { AccountsManagementSpy } from './';

const ButtonGroup = Button.Group;

class SettingsAccountsManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      GPR: null,
      initialValues: null,
      inline: !window.matchMedia('(max-width: 600px)').matches,
      editing: false,
      selectedClientId: null,
    };
    /*window.addEventListener('resizeeee', (event) => {
            this.setState({
                inline: !window.matchMedia('(max-width: 600px)').matches,
            });
        });*/

    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    // do we really need this call?
    // await this.props.dispatch(UACActions.postATR());
    const response = await this.props.dispatch(UACActions.postGPR({}));
    this.setState({ GPR: response.payload });
  }

  submit = async (values) => {
    const { language } = this.props;

    //
    // this.setState((previousState, currentProps) => {
    //     return {
    //         ...previousState,
    //         initialValues: {ClientID: values.ClientID, ...values},
    //     };
    // });

    //const arrChange = Object.keys(difference);
    // const ClientPermissionsChanged = values.ClientPermissions.map(
    //     (item, index) => {
    //         if (arrChange.includes(index.toString())) {
    //             return item;
    //         }
    //     }).filter(x => x !== undefined);

    const valuesData = JSON.parse(JSON.stringify(values));
    for (let i = 0; i < valuesData.ClientPermissions.length; i++) {
      valuesData.ClientPermissions[i].Permission = valuesData.ClientPermissions[i].Permission.toString();
    }

    const data = {
      DocType: 'AccPerm',
      Content: JSON.stringify(valuesData),
    };
    this.setState({ ...this.state, submitting: true, selectedClientId: values.ClientID });
    await this.props.dispatch(DMCActions.postSDR({ ...data, Language: language })).then((response) => {
      if (response.payload && response.payload.IsSuccessfull && response.payload.RQ_ID !== null) {
        this.setState({
          approve: true,
          RQ_ID: response.payload.RQ_ID,
          AuthType: response.payload.AuthType,
          Code: response.payload.ControlCode,
          submitting: true,
        });
      } else {
        this.setState({
          errors: response.payload.ErrorCaptions,
          submitting: false,
          editing: false,
        });
      }
    });
    return;
  };
  cancelApprove = (ErrorCode) => {
    let message = ErrorCode;
    if (message?.replace) {
      message = message.replace('.', '');
    }
    this.setState(() => ({
      approve: false,
      RQ_ID: '',
      AuthType: null,
      Code: null,
      submitting: false,
    }));
    Notification({
      type: 'error',
      message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.PermissionsChangeFailed,
      description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.PermissionsChangeFailed,
    });
    //window.location.reload(false);
  };

  successApprove = async () => {
    Notification({
      type: 'success',
      message: i18.NotificationTitles.PermissionsChanged,
      description: i18.NotificationMessages.PermissionsChangedSuccessfully,
    });
    this.setState({
      approve: false,
      RQ_ID: '',
      AuthType: null,
      Code: null,
      submitting: false,
      editing: false,
    });
    const response = await this.props.dispatch(UACActions.postGPR({}));
    this.setState({ GPR: response.payload });
  };

  submitSmsApprove = (values) => {
    const { dispatch } = this.props;
    dispatch(PMCActions.postAPSMSR(values)).then((response) => {
      const IsSuccessfull = checkJsonProp(response, 'payload.IsSuccessfull');
      const ErrorCode = checkJsonProp(response, 'payload.ErrorCode');
      if (IsSuccessfull) {
        this.successApprove();
      } else {
        this.cancelApprove(ErrorCode);
      }
    });
  };

  submitMobileApprove = async () => {
    const { dispatch } = this.props;
    const { RQ_ID } = this.state;
    const response = await dispatch(PMCActions.postAPESCR({ RQ_ID: RQ_ID }));
    const AuthCheckResult = checkJsonProp(response, 'payload.AuthCheckResult');
    const IsSuccessfull = checkJsonProp(response, 'payload.IsSuccessfull');
    const ErrorCode = checkJsonProp(response, 'payload.ErrorCode');

    if (AuthCheckResult === null) {
      return true;
    }
    if (AuthCheckResult === false) {
      this.cancelApprove(ErrorCode);
    } else if (AuthCheckResult === true && IsSuccessfull) {
      this.successApprove();
    }
    return false;
  };

  render() {
    const { sendGPR, ATR, GPR } = this.props;

    return (
      <>
        <div className="eb_content_subtitle">{trb(i18.ContentTitles.SettingsAccountsManagement)}</div>
        <Container>
          {sendGPR && <ContentLoader />}
          {!sendGPR && (
            <>
              {!GPR ? (
                <ContentNoData id="AccountsManagementGPR" />
              ) : (
                <Form
                  onSubmit={this.submit}
                  initialValues={this.state.initialValues}
                  render={({ handleSubmit, form, values }) => {
                    const client = GPR.UsersPermissions.find((x) => x.ID === values.ClientID);
                    if (!client && GPR.UsersPermissions) {
                      const clientId = this.state.selectedClientId ?? GPR.UsersPermissions?.[0]?.ID;
                      const ClientPermissions = GPR?.UsersPermissions?.find((e) => e.ID === clientId)?.PermissionsList;
                      form.change('ClientID', clientId);
                      form.change('ClientPermissions', ClientPermissions);
                    }

                    return (
                      <form
                        id="AccountsManagementForm"
                        className="mb-3"
                        onSubmit={handleSubmit}
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: '100%',
                        }}
                      >
                        <FormField
                          name="ClientID"
                          title={i18.Labels.ChoosePersonOrCompany}
                          component={FormFieldType.select}
                          options={this.state.GPR?.UsersPermissions}
                          className="mb-5"
                          classNameField="col-12"
                          optionsLabel="ClientFullName"
                          optionsValue="ID"
                          inline={this.state.inline}
                          validate={required}
                          disabled={this.state.submitting}
                        />
                        {!!this.state?.GPR?.UsersPermissions?.length && (
                          <FormSpy
                            form={form}
                            data={values}
                            //submitting={this.state.submitting}
                            permissions={this.state.GPR?.UsersPermissions}
                            editing={this.state.editing}
                            component={AccountsManagementSpy}
                          />
                        )}
                        {this.state.errors && (
                          <Alert
                            className="mb-3"
                            message={trb(i18.ErrorTitles.AccountsLimitsChangeError)}
                            description={
                              <ul>
                                {this.state.errors.map((item, index) => (
                                  <li key={index}>
                                    {item.ErrorCode} - {item.ErrorCaption}
                                  </li>
                                ))}
                              </ul>
                            }
                            type="error"
                          />
                        )}
                        {this.state.editing ? (
                          <div className="mb-3 text-right">
                            {ATR.AuthType === 'eSign' && (
                              <>
                                <div className="d-none d-md-block">
                                  <Button
                                    onClick={() => this.setState({ editing: false })}
                                    disabled={this.state.submitting && this.state.approve}
                                    type="default"
                                    className="mr-2"
                                    id="AccountsManagementCancelButtonLG"
                                  >
                                    {trb(i18.Buttons.Cancel)}
                                  </Button>
                                  <Button
                                    id="AccountsManagementSubmitButtonLG"
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.state.submitting && !this.state.approve}
                                    disabled={(this.state.submitting && this.state.approve) || isEqual([...client.PermissionsList], [...(values?.ClientPermissions ?? [])])}
                                  >
                                    {trb(i18.Buttons.ChangeAccountPermissionsSave)}
                                  </Button>
                                </div>
                                <div className="d-block d-md-none">
                                  <Button
                                    onClick={() => this.setState({ editing: false })}
                                    disabled={this.state.submitting && this.state.approve}
                                    type="default"
                                    className="w-100 mb-2"
                                    id="AccountsManagementCancelButtonMD"
                                  >
                                    {trb(i18.Buttons.Cancel)}
                                  </Button>
                                  <Button
                                    id="AccountsManagementSubmitButtonMD"
                                    type="primary"
                                    htmlType="submit"
                                    className="w-100"
                                    loading={this.state.submitting && !this.state.approve}
                                    disabled={(this.state.submitting && this.state.approve) || isEqual([...client.PermissionsList], [...(values?.ClientPermissions ?? [])])}
                                  >
                                    {trb(i18.Buttons.ChangeAccountPermissionsSave)}
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          <>
                            {values.ClientID && this.state.GPR?.UsersPermissions?.find((e) => e.ID === values.ClientID)?.PermissionsList?.length && (
                              <div className="mb-3 text-right">
                                {ATR.AuthType === 'eSign' && (
                                  <ButtonGroup className="d-block">
                                    <Button
                                      id="AccountsManagementEditButton"
                                      type="primary"
                                      onClick={() => this.setState({ editing: true })}
                                    >
                                      {trb(i18.Buttons.ChangeAccountPermissions)}
                                    </Button>
                                  </ButtonGroup>
                                )}
                              </div>
                            )}
                          </>
                        )}

                        {this.state.approve && (
                          <OperationApproval
                            RQ_ID={this.state.RQ_ID}
                            AuthType={this.state.AuthType}
                            code={this.state.Code}
                            cancelApprove={this.cancelApprove}
                            successApprove={this.successApprove}
                            submitSmsApprove={this.submitSmsApprove}
                            submitMobileApprove={this.submitMobileApprove}
                          />
                        )}
                      </form>
                    );
                  }}
                />
              )}
            </>
          )}
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { sendGPR, GPR } = state.UACReducers;
  const { ATR } = state.UACReducers;
  const { language } = state.languageReducer;
  return {
    sendGPR,
    GPR,
    ATR,
    language,
  };
}

const connectedSettingsAccountsManagement = connect(mapStateToProps)(SettingsAccountsManagement);
export { connectedSettingsAccountsManagement as SettingsAccountsManagement };
