import { useSelector } from 'react-redux';

import { i18, trb } from 'src/Utilities';
import { Button } from 'src/shared';

interface IProps {
  handleChangeLimits: () => void;
  submittingNewLimits: boolean;
  edit: boolean;
  isEqual: boolean;
}

export const UserLimitsActions = ({ handleChangeLimits, submittingNewLimits, edit, isEqual }: IProps) => {
  const { ATR } = useSelector((state: any) => state.UACReducers);

  const buttons = [
    { groupClassName: 'd-block d-md-none', buttonClassName: edit ? 'w-100 mb-2' : 'w-100' },
    { groupClassName: 'd-none d-md-block', buttonClassName: 'mr-2' },
  ];

  return (
    <div className="mb-3 text-right">
      {ATR.AuthType === 'eSign' &&
        buttons.map(({ groupClassName, buttonClassName }, index) => (
          <div
            className={groupClassName}
            key={index}
          >
            {edit ? (
              <>
                <Button
                  onClick={handleChangeLimits}
                  disabled={submittingNewLimits}
                  className={buttonClassName}
                >
                  {trb(i18.Buttons.Cancel)}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  className={buttonClassName}
                  loading={submittingNewLimits}
                  disabled={isEqual || submittingNewLimits}
                >
                  {trb(i18.Buttons.ChangeAccountLimitsSave)}
                </Button>
              </>
            ) : (
              <Button
                onClick={handleChangeLimits}
                type="primary"
                className={buttonClassName}
                loading={submittingNewLimits}
              >
                {trb(i18.Buttons.ChangeAccountLimits)}
              </Button>
            )}
          </div>
        ))}
    </div>
  );
};
