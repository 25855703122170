import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Pagination, Row, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { AMCActions, AMCConstants, DMCActions, DMCConstants } from 'src/Redux';
import {
  blobToFileDownload,
  checkJsonExist,
  checkJsonProp,
  CustomIcon,
  formatDate,
  useGetWindowSize,
  i18,
  logError,
  numberFormat,
  translationGroups,
  trb,
  getIDFLink,
} from 'src/Utilities';
import { ContentLoader, ContentNoData, EBTableExpand, Number } from 'src/Components';
import { Summary } from './Summary';

import moment from 'moment';
import { useLocation } from 'react-router-dom';

export const InvoiceListContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const windowSize = useGetWindowSize();
  const { ADR, COMPANY, sendADR, filterData, ADROD } = useSelector((state) => state.AMCReducers);
  const { MALR } = useSelector((state) => state.UACReducers);
  const { language } = useSelector((state) => state.languageReducer);

  const [overdraftInfoDesc, setOverdraftInfoDesc] = useState(null);
  const [loadOverdraftInfo, setLoadOverdraftInfo] = useState(false);

  useEffect(() => {
    if (location.state?.accountId /* && location.state?.accountId !== ADR?.AccountWithDetails?.AccountID*/) {
      reloadData(location.state?.accountId);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateADROD = (adrResp) => {
    let overdraftItem = null;
    // if(location?.state?.accountId){
    //     overdraftItem = ADR?.Accounts?.find(
    //         itm => itm.AccountID === location?.state?.accountId
    //     )
    //     debugger;
    // } else

    {
      const accountNr = adrResp?.AccountWithDetails?.AccountNumber?.replace(/ /gi, '');

      overdraftItem = adrResp?.Accounts?.find((itm) => itm.CurrentAccountIban === accountNr);
    }

    if (overdraftItem /* && overdraftItem.AccountID !== ADROD?.AccountWithDetails?.AccountID */) {
      setLoadOverdraftInfo(true);

      const aDRData = {
        AccountID: overdraftItem.AccountID,
        FilterID: 2,
        FilterBeginDate: '',
        FilterEndDate: '',
      };

      dispatch(AMCActions.postADROD(aDRData));
    } else {
      applyADR(adrResp);
    }
  };

  const reloadData = async (AccountID) => {
    const aDRData = {
      AccountID,
      FilterID: 2,
      FilterBeginDate: '',
      FilterEndDate: '',
    };
    const resp = await dispatch(AMCActions.postADR(aDRData));
    if (resp?.type === AMCConstants.POST_ADR_SUCCESS) {
      updateADROD(resp.payload);
    }
  };

  useEffect(() => {
    if (!ADROD) {
      return;
    }

    applyADR(ADROD);
  }, [ADROD]);

  const makeArraysSameSize = (arr1, arr2) => {
    const smallerArr = arr1.length > arr2.length ? arr2 : arr1;
    const biggerArr = arr1.length < arr2.length ? arr2 : arr1;
    const diff = biggerArr.length - smallerArr.length;
    for (let i = 0; i < diff; i++) {
      smallerArr.push({
        Caption: '',
        Value: '',
        ValueDecimal: '',
      });
    }
  };

  const applyADR = (source) => {
    if (!source) return;
    const { ContractDesc1, ContractDesc2, AccountWithDetails } = source;
    if (ContractDesc1?.length && ContractDesc2?.length) {
      if (ContractDesc1.length !== ContractDesc2.length) {
        makeArraysSameSize(ContractDesc1, ContractDesc2);
      }
      setOverdraftInfoDesc({
        ContractDesc1,
        ContractDesc2,
        AccountWithDetails: {
          AccountTypeCode: AccountWithDetails.AccountTypeCode,
        },
      });
      setLoadOverdraftInfo(false);
    }
  };
  const changePage = (page) => {
    const values = Object.assign(filterData ?? {}, { PageNumberToShow: page });
    window.scrollTo(0, 0);
    dispatch(AMCActions.postADR(values));
  };

  const downloadPayment = async (item) => {
    try {
      const response = await dispatch(DMCActions.postSPPDF(item, language));
      if (response?.type === DMCConstants.POST_DMC_SPPDF_SUCCESS) {
        blobToFileDownload('payment.pdf', response.payload, 'application/pdf');
      }
    } catch (error) {
      logError(error);
    }
  };

  const hideContractDescCaptions = ['administrationFee', 'interestType', 'otherSums', 'unusedLoanSum', 'interestRateDateTill'];
  const filterDescValues = (array) => {
    return array?.filter(({ Caption }) => !hideContractDescCaptions.includes(Caption));
  };

  const depositInfo = () => {
    let { ContractDesc1, ContractDesc2, AccountWithDetails } = overdraftInfoDesc ?? ADR ?? {};
    const AccountTypeCode = AccountWithDetails?.AccountTypeCode;
    ContractDesc1 = filterDescValues(ContractDesc1);
    ContractDesc2 = filterDescValues(ContractDesc2);

    if (AccountTypeCode === '4' && ContractDesc2.length) {
      ContractDesc2 = ContractDesc2.filter((item) => item.Caption !== 'delayLoanPrincipal');
    }
    if (AccountTypeCode === '3' || AccountTypeCode === '4') {
      ContractDesc2 = ContractDesc2.filter((item) => item.Caption !== 'delayTotal');
    }

    if (!ContractDesc1?.length || !ContractDesc2.length) {
      return null;
    }

    const lgClass = (12 / (ContractDesc1.length + ContractDesc2.length)) >> 0;
    let lgLastClass = 12 - (ContractDesc1.length + ContractDesc2.length) * lgClass,
      lgClassArray = Array.apply(null, {
        length: ContractDesc1.length + ContractDesc2.length,
      }).map(() => lgClass),
      index = ContractDesc1.length + ContractDesc2.length - 1;

    while (lgLastClass) {
      lgClassArray[index] = lgClassArray[index] + 1;
      lgLastClass--;
      index = index === 0 ? ContractDesc1.length + ContractDesc2.length - 1 : index - 1;
    }

    const getLabel = (x) => {
      const result = i18.Labels[x];
      if (result) {
        return result;
      }
      return x;
    };

    const getValue = (item) => {
      const ValueDecimal = item?.ValueDecimal;
      const Value = item?.Value;
      if (ValueDecimal || ValueDecimal === 0) {
        return ValueDecimal;
      }
      if (item.Caption === 'autoRenew') {
        return trb(translationGroups.Enums + Value);
      }
      return Value;
    };

    return (
      <Card id="DepositInfoCard">
        <Row className="w-100">
          {ContractDesc1.map((item, index) => {
            const value = getValue(item);

            if (value !== null && item.Caption) {
              return (
                <Col
                  xs={24}
                  md={12}
                  lg={8}
                  xl={6}
                  key={index}
                  className="py-2"
                >
                  <div className="text-black-70">{trb(getLabel(item?.Caption))}</div>
                  <div className="text-black eb_fw_600">
                    {typeof value === 'number' ? (
                      <Number
                        format={item.Caption === 'delayDays' ? numberFormat().number : numberFormat().currency}
                        value={value}
                      />
                    ) : (
                      value
                    )}
                  </div>
                </Col>
              );
            } else return null;
          })}
          {ContractDesc2.map((item, index) => {
            const value = getValue(item);

            if (value !== null && item.Caption) {
              return (
                <Col
                  xs={24}
                  md={12}
                  lg={8}
                  xl={6}
                  key={index}
                  className="py-2"
                >
                  <div className="text-black-70">{trb(getLabel(item?.Caption))}</div>
                  <div className="text-black eb_fw_600">
                    {typeof value === 'number' ? (
                      <Number
                        format={numberFormat().currency}
                        value={value}
                      />
                    ) : ['RBF', 'LimitedRBF', 'CreditLine'].includes(value) ? (
                      trb(translationGroups.CreditType + value)
                    ) : (
                      value
                    )}
                  </div>
                </Col>
              );
            } else return null;
          })}
        </Row>
      </Card>
    );
  };

  const blocksReservations = () => {
    let Reservations = ADR?.Reservations;
    if (!Reservations) {
      return null;
    }

    if (!Reservations.length) {
      return null;
    }

    const headers = [
      {
        title: trb(i18.Labels.InvoiceData), //'Data',
        className: 'col-lg-2',
      },
      {
        title: trb(i18.Labels.InvoiceDescription), //'Aprašymas',
        className: 'col-lg-6 word-brake-all',
      },
      {
        title: trb(i18.Labels.InvoiceSum), //'Suma',
        className: 'col-lg-4',
      },
    ];

    const mapData = [
      {
        headerClassName: 'col-6 col-md-6 col-lg-2 order-1 order-sm-1 order-md-1 order-lg-1',
        values: [
          {
            name: 'ReservationDateFormatted',
          },
        ],
      },
      {
        headerClassName: 'col-12 col-md-12 col-lg-6 order-3 order-sm-3 order-md-3 order-lg-2',
        values: [
          {
            name: 'Description',
            className: 'text-word-wrap',
          },
        ],
      },
      {
        headerClassName: 'col-6 col-md-6 col-lg-4 text-right order-2 order-sm-2 order-md-2 order-lg-3',
        values: [
          {
            name: 'AmountFormatted',
            className: 'text-black-50',
          },
        ],
      },
    ];

    Reservations = Reservations.filter((item) => item.IsActive && Math.abs(item.Amount) > 0).map((item) => {
      return {
        ...item,
        AmountFormatted: trb(i18.Labels.AmountCurrency, {
          amount: item.AmountFormatted,
          currency: item.Currency,
        }),
      };
    });

    return (
      <div id="InvoiceReservations">
        <h1 className="eb_content_subtitle">{trb(i18.Labels.Reservations)}</h1>
        <EBTableExpand
          mapData={mapData}
          headers={headers}
          data={Reservations}
        />
      </div>
    );
  };

  const blocksMoney = () => {
    if (!ADR?.Blocks) {
      return null;
    }

    if (!ADR?.Blocks?.length) {
      return null;
    }

    const headers = [
      {
        title: trb(i18.Labels.InvoiceData), //'Data',
        className: 'col-lg-2',
      },
      {
        title: trb(i18.Labels.InvoiceDescription), //'Aprašymas',
        className: 'col-lg-6 word-brake-all',
      },
      {
        title: trb(i18.Labels.InvoiceSum), //'Suma',
        className: 'col-lg-4',
      },
    ];

    const mapData = [
      {
        headerClassName: 'col-6 col-md-6 col-lg-2 order-1 order-sm-1 order-md-1 order-lg-1',
        values: [
          {
            name: 'BlockDateFormatted',
          },
        ],
      },
      {
        headerClassName: 'col-12 col-md-12 col-lg-6 order-3 order-sm-3 order-md-3 order-lg-2',
        values: [
          {
            name: 'Description',
            className: 'text-word-wrap',
          },
        ],
      },
      {
        headerClassName: 'col-6 col-md-6 col-lg-4 text-right order-2 order-sm-2 order-md-2 order-lg-3',
        values: [
          {
            name: 'AmountFormatted',
            className: 'text-black-50',
          },
        ],
      },
    ];

    return (
      <div id="InvoiceBlocksPayment">
        <h1 className="eb_content_subtitle">{trb(i18.Labels.BlocksPayment)}</h1>
        <EBTableExpand
          id="BlocksPaymentTable"
          className="w-100"
          mapData={mapData}
          headers={headers}
          data={ADR?.Blocks}
        />
      </div>
    );
  };

  const notesInfo = () => {
    const ActiveAccount = MALR?.ManagedAccounts ? MALR.ManagedAccounts.find((x) => x.IsActive === true) : null,
      IDF = checkJsonExist(ActiveAccount, 'IDF') ? ActiveAccount.IDF : false,
      typeCodesSee = [131, 132, 22, 23, 24, 3, 4, 133],
      typeCode = ADR?.AccountWithDetails?.AccountTypeCode ? parseInt(ADR.AccountWithDetails.AccountTypeCode) : null;

    if (!typeCodesSee.includes(typeCode)) {
      return null;
    }

    if (IDF) {
      return (
        <>
          <strong>{trb(i18.Labels.Note)} </strong>
          {trb(i18.Labels.BankText)}
          <a
            href={COMPANY?.[getIDFLink(language)]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {COMPANY?.[getIDFLink(language)]}
          </a>
        </>
      );
    }

    return null;
  };

  const sortItems = (a, b) => {
    if (checkJsonProp(a, 'Income.0.Value') && checkJsonProp(b, 'Income.0.Value')) return a.Income[0].Value > b.Income[0].Value ? -1 : a.Income[0].Value < b.Income[0].Value ? 1 : 0;
    if (checkJsonProp(a, 'Expediture.0.Value') && checkJsonProp(b, 'Expediture.0.Value')) {
      return a.Expediture[0].Value > b.Expediture[0].Value ? 1 : a.Expediture[0].Value < b.Expediture[0].Value ? -1 : 0;
    }
    if (checkJsonProp(a, 'Income.0.Value') && checkJsonProp(b, 'Expediture.0.Value')) return -1;
    if (checkJsonProp(b, 'Income.0.Value') && checkJsonProp(a, 'Expediture.0.Value')) return 1;
  };

  if (sendADR) {
    return <ContentLoader />;
  }

  if (!ADR?.AccountWithDetails?.AccountDetails) {
    return <ContentNoData id="ADRAccountDetails" />;
  }

  const checkAccounts = ADR?.Accounts?.length;

  if (!checkAccounts) {
    return <div className="eb_content_no_data" />;
  }

  const columns = [
      {
        title: '',
        dataIndex: 'ID',
        render: (el, item) => {
          if (item?.Expediture?.[0]?.Value > 0)
            return (
              <CustomIcon
                style={{ marginLeft: 0, cursor: 'auto' }}
                className="payment_type"
                size={18}
                type="custom"
                icon="payment"
              />
            );
          if (item?.Income?.[0]?.Value > 0)
            return (
              <CustomIcon
                style={{ marginLeft: 0, cursor: 'auto' }}
                className="payment_type payment_type_active"
                size={18}
                type="custom"
                icon="payment"
              />
            );
        },
        width: 85,
      },
      {
        title: trb(i18.Labels.InvoiceDate),
        dataIndex: 'Date',
        render: (element) => <span className="text-nowrap">{formatDate(element)}</span>,
        sorter: (a, b) => moment(a.Date).valueOf() - moment(b.Date).valueOf(),
        width: 140,
      },
      {
        title: trb(i18.Labels.InvoicePayer),
        dataIndex: 'Pay_Rec',
        //render: (element) => <span className="eb_fw_600">{element}</span>,
        sorter: (a, b) => (a.Pay_Rec > b.Pay_Rec ? -1 : a.Pay_Rec < b.Pay_Rec ? 1 : 0),
        width: 210,
      },
      {
        title: trb(i18.Labels.InvoiceDescription),
        dataIndex: 'Description',
        sorter: (a, b) => (a.Description > b.Description ? -1 : a.Description < b.Description ? 1 : 0),
        className: 'word-brake-all',
        width: 400,
      },
      {
        title: trb(i18.Labels.InvoiceAmount),
        align: 'right',
        dataIndex: 'Income.0.Value',
        sorter: sortItems,
        render: (element, item) => (
          <>
            <div className="text-primary text-nowrap float-right">{item?.Income?.[0]?.Text}</div>
            <div className="text-black text-nowrap float-right">{item?.Expediture?.[0]?.Text ? `- ${item.Expediture[0].Text}` : null}</div>
            <div className="text-black-50 float-right">{item?.PaymentFee?.[0]?.Text ? ` ${trb(i18.Labels.InvoiceComissionFee)}: ${item.PaymentFee[0].Text}` : null}</div>
          </>
        ),
        width: 160,
      },
      {
        title: '',
        dataIndex: 'ID',
        render: (el, item) => {
          const { Pay_RecBankAccount } = item;
          return (
            <DownloadOutlined
              style={!Pay_RecBankAccount ? { visibility: 'hidden' } : { fontSize: 20, opacity: 0.65 }}
              onClick={() => downloadPayment(item)}
            />
          );
        },
        width: 70,
      },
    ],
    columnsMobile = [
      {
        title: trb(i18.Labels.InvoicePayer),
        dataIndex: 'Pay_Rec',
        sorter: (a, b) => (a.Pay_Rec > b.Pay_Rec ? -1 : a.Pay_Rec < b.Pay_Rec ? 1 : 0),
      },
      {
        title: trb(i18.Labels.InvoiceAmount),
        dataIndex: 'Income.0.Value',
        sorter: sortItems,
        render: (element, item) => (
          <>
            <div className="text-primary text-nowrap float-right">{item?.Income?.[0]?.Text ? `+ ${item.Income[0].Text}` : ''}</div>
            <div className="text-black text-nowrap float-right">{item?.Expediture?.[0]?.Text ? `- ${item.Expediture[0].Text}` : ''}</div>
            <div className="text-black-50 text-nowrap float-right">{item?.PaymentFee?.[0]?.Text ? ` ${trb(i18.Labels.InvoiceComissionFee)}: ${item.PaymentFee[0].Text}` : ''}</div>
          </>
        ),
      },
    ];

  const currentAccount = {
    ...ADR?.AccountWithDetails,
    BlockedAmount: ADR?.Accounts?.find((e) => e.AccountID === ADR?.AccountWithDetails?.AccountID)?.BlockedAmount,
    DisposableBalance: ADR?.Accounts?.find((e) => e.AccountID === ADR?.AccountWithDetails?.AccountID)?.DisposableBalance,
    AccountCurrency: ADR?.Accounts?.find((e) => e.AccountID === ADR?.AccountWithDetails?.AccountID)?.AccountCurrency,
    NationalBalance: ADR?.Accounts?.find((e) => e.AccountID === ADR?.AccountWithDetails?.AccountID)?.AmountNationalCurrency,
  };

  const summary = [
    {
      text: trb(i18.Labels.InvoiceInitialBalance), //'Pradinis likutis',
      name: 'InitialBalance.0.Value',
      suffix: 'InitialBalance.0.Currency',
    },
    {
      text: trb(i18.Labels.InvoiceTotalRevenue), //'Viso įplaukų',
      name: 'TurnOverIncome',
      suffix: 'AccountCurrency',
    },
    {
      text: trb(i18.Labels.InvoiceTotalCost), //'Viso išlaidų',
      name: 'TurnOverOutcome',
      suffix: 'AccountCurrency',
    },
    {
      text: trb(i18.Labels.InvoiceFinalBalance), //'Galutinis likutis',
      name: 'FinalBalance.0.Value',
      suffix: 'FinalBalance.0.Currency',
    },
    ...(currentAccount?.AccountCurrency !== import.meta.env.VITE_NATONIAL_CURRENCY
      ? [
          {
            text: trb(i18.Labels.EquivalentBalance), // 'Ekvivalentas likutis',
            name: 'NationalBalance',
            suffixString: import.meta.env.VITE_NATONIAL_CURRENCY,
          },
        ]
      : []),
    {
      text: trb(i18.Labels.InvoiceBlocked), //'Blokuota',
      name: 'BlockedAmount.0.Value',
      suffix: 'BlockedAmount.0.Currency',
    },
    {
      text: trb(i18.Labels.InvoiceReserved), //'Rezervuota',
      name: 'Reserved.0.Value',
      suffix: 'Reserved.0.Currency',
    },
    {
      text: trb(i18.Labels.DisposableBalance), //'Rezervuota',
      name: 'DisposableBalance.0.Value',
      suffix: 'DisposableBalance.0.Currency',
    },
  ];

  const currentPage = parseInt(ADR?.AccountWithDetails?.PageNumberToShow);
  const numberOfPages = parseInt(ADR?.AccountWithDetails?.NumberOfPages);
  const accountDetails = ADR?.AccountWithDetails?.AccountDetails;
  const dataSource = [];
  accountDetails.forEach((item) => {
    const { Pay_RecBankAccount, Pay_Rec } = item;
    if (Pay_RecBankAccount && Pay_RecBankAccount.includes('.')) {
      dataSource.push({
        ...item,
        Pay_RecBankAccount: null,
        Pay_Rec: Pay_Rec && Pay_Rec.includes('.') ? Pay_Rec.substring(0, Pay_Rec.length - 13) : Pay_Rec,
      });
    } else {
      dataSource.push(item);
    }
  });

  return (
    <div
      className="py-3 p-md-0"
      style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}
    >
      {loadOverdraftInfo ? <ContentLoader /> : depositInfo()}
      <Table
        id="InvoiceListTable"
        pagination={false}
        dataSource={dataSource}
        columns={windowSize.mobileCheck ? columnsMobile : columns}
        className="my-5 mx-0 overflow_x_auto invoice-list-table"
        rowKey="ID"
        tableLayout="fixed"
      />
      {numberOfPages !== 1 && (
        <Pagination
          current={currentPage}
          onChange={changePage}
          total={numberOfPages * 10}
          showSizeChanger={false}
          className="text-center my-3"
        />
      )}
      {blocksMoney()}
      {blocksReservations()}
      {!windowSize.mobileCheck && (
        <Summary
          data={currentAccount}
          headers={summary}
        />
      )}
      {notesInfo()}
    </div>
  );
};

