import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { DepositActions, PMCActions } from 'src/Redux';
import { i18, trb } from 'src/Utilities';
import { ContentLoader, Notification, OperationApproval } from 'src/Components';

export const DepositsSignContainer = ({ nextStep }) => {
  const { sendDepositOffer, depositOffer, sendConfirmDeposit, confirmDepositData } = useSelector((state) => state.DepositReducers);
  const { language } = useSelector((state) => state.languageReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(DepositActions.clearPostConfirmDepositData());
    };
  }, []);

  const handleSign = () => {
    dispatch(
      DepositActions.postConfirmDeposit({
        productId: depositOffer.Response.productId,
        Language: language,
      })
    );
  };

  const successApprove = () => {
    nextStep();
  };

  const cancelApprove = (ErrorCode) => {
    let message = ErrorCode;
    if (message?.replace) {
      message = message.replace('.', '');
    }
    Notification({
      type: 'warning',
      message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.DepositSignFail,
      description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.DepositSignFail,
    });
    dispatch(DepositActions.clearPostConfirmDepositData());
  };

  const submitSmsApprove = async (values) => {
    const response = await dispatch(PMCActions.postAPESCR(values));
    const ErrorCode = response?.payload.ErrorCode;

    if (response?.payload?.IsSuccessfull) {
      successApprove();
    } else {
      cancelApprove(ErrorCode);
    }
  };

  const submitMobileApprove = async () => {
    const response = await dispatch(PMCActions.postAPESCR({ RQ_ID: confirmDepositData.RQ_ID }));
    const AuthCheckResult = response?.payload?.AuthCheckResult;
    const IsSuccessfull = response?.payload?.IsSuccessfull;
    const ErrorCode = response?.payload?.ErrorCode;

    if (AuthCheckResult === null) {
      return true;
    }
    if (AuthCheckResult === false) {
      cancelApprove(ErrorCode);
    } else if (AuthCheckResult && IsSuccessfull) {
      successApprove();
    }
    return false;
  };

  return (
    <div className="w-100 mt-3">
      {sendDepositOffer && !depositOffer && <ContentLoader />}

      {depositOffer && !confirmDepositData && (
        <>
          <embed
            height="1190"
            className="w-100"
            src={`data:application/pdf;base64,${depositOffer.Response.pdfBytes}`}
          />

          <div className="my-3 text-right d-block col-5 col-md-12 pl-4 pl-md-3">
            <Button
              id="DepositsSignButton"
              onClick={handleSign}
              type="primary"
              htmlType="button"
              disabled={sendConfirmDeposit}
              loading={sendConfirmDeposit}
            >
              {trb(i18.Buttons.Sign)}
            </Button>
          </div>
        </>
      )}

      {confirmDepositData && (
        <>
          <div className="text-center mb-4">{trb(i18.Labels.SignDeposit)}</div>
          <OperationApproval
            RQ_ID={confirmDepositData.RQ_ID}
            AuthType={confirmDepositData.AuthType}
            code={confirmDepositData.ControlCode}
            cancelApprove={cancelApprove}
            successApprove={successApprove}
            submitSmsApprove={submitSmsApprove}
            submitMobileApprove={submitMobileApprove}
          />
        </>
      )}
    </div>
  );
};
