import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageLoader } from 'src/Components';
import { routerPath } from 'src/Config';
import { UACActions } from 'src/Redux';
import { getCookieByName } from 'src/utils';

export const IdentificationRoom = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Language = useSelector((state: any) => state.languageReducer?.language);

  const [searchParams] = useSearchParams();

  const doLogin = async () => {
    const loginSessionGuid = getCookieByName('login_session_guid');
    const LoginCode = getCookieByName('login_code');
    const LoginToken = searchParams.get('state');
    if (loginSessionGuid === LoginToken && LoginCode) {
      const LoginSystem = 'EidEasy';
      const LoginAuthCode = searchParams.get('code');

      const result = await dispatch(
        UACActions.postCheckLogin({
          Language,
          LoginCode,
          LoginSystem,
          LoginToken,
          LoginAuthCode,
        }) as any
      );
      if (result?.payload?.IsAuth === true) {
        navigate(routerPath.index);
      } else {
        navigate(routerPath.login);
      }
    } else {
      navigate(routerPath.login);
    }
  };

  useEffect(() => {
    doLogin();
  }, []);

  return <PageLoader />;
};
