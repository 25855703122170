import React, { useEffect, useState } from 'react';
import { Container, ContentLoader, ContentNoData, FormDecoratorSwitch, generateFeeCode, Notification } from 'src/Components';
import { Steps } from 'antd';
import { PaymentFill, PaymentPreview, PaymentUnsuccess, PaymentWaiting } from 'src/Containers';
import { useDispatch, useSelector } from 'react-redux';
import { checkJsonExist, CustomIcon, i18, logError, Payment, Sleep, PAYMENT_SYSTEMS, throwError, translationGroups, trb, CURRENCIES } from 'src/Utilities';
import { AMCActions, FeeActions, FeeConstants, PMCActions } from 'src/Redux';

export const PaymentFee = (props) => {
  const dispatch = useDispatch();
  let lastFullTextSearch = null;
  let timeoutFullTextSearch = null;
  const {
    sendPTR,
    PTR,
    PVR,
    PayPersonTypes,
    sendPayPersonTypes,
    historicalPayments,
    checkIBANResult,
    sendCheckIBAN,
    sendGetFxRate,
    target2Data,
    checkIBANLength,
    checkIBANValid,
    forexPayments,
  } = useSelector((state) => state.PMCReducers);
  const { sendFeeList, feeList } = useSelector((state) => state.FeeReducers);
  const { MALR, sendMALR } = useSelector((state) => state.UACReducers);
  const { language } = useSelector((state) => state.languageReducer);
  const amcReducers = useSelector((state) => state.AMCReducers);
  const { forexRateRequestData, forexDraftInfo } = amcReducers;
  // const {translations } = useSelector((state) => state.translationReducer);

  const paymentType = Payment.FEE;

  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [saveSign, setSaveSign] = useState(false);
  const [withError, setWithError] = useState(null);
  const [approve, setApprove] = useState(null);
  const [data, setData] = useState(null);
  const [decorator, setDecorator] = useState(null);
  const [RQ_ID, setRQID] = useState(null);
  const [AuthType, setAuthType] = useState(null);
  const [Code, setCode] = useState(null);

  useEffect(() => {
    const componentMounted = async () => {
      let lng = language.split('-')[0];

      await dispatch(PMCActions.getPayPersonTypes({ language: lng }));
      if (paymentType === Payment.FEE && !sendFeeList && !feeList) {
        await dispatch(FeeActions.getFeeList());
      }
      localStorage.removeItem('SavedIBAN');
      localStorage.removeItem('SavedCurrency');
      await dispatch(PMCActions.clearCheckIBANMember());
      await dispatch(PMCActions.clearHistoricalPayments());
      await dispatch(PMCActions.clearPVR());
    };
    componentMounted();
    window.history.replaceState({}, document.title);
  }, []);

  useEffect(() => {
    resetPayment();
    const paymentTypeChanged = async () => {
      setData(null);
      setErrors(null);
      setCurrentStep(0);
      setSaveSign(false);
      setDecorator(
        FormDecoratorSwitch({
          name: paymentType,
          feePaymentHistory: feePaymentHistory,
        })
      );

      localStorage.removeItem('SavedIBAN');
      localStorage.removeItem('SavedCurrency');
      await dispatch(PMCActions.clearCheckIBANMember());
      await dispatch(PMCActions.clearHistoricalPayments());
      await dispatch(PMCActions.clearPVR());
      if (paymentType === Payment.FEE && !sendFeeList && !feeList) {
        await dispatch(FeeActions.getFeeList());
      }
    };
    if (paymentType) {
      paymentTypeChanged();
    }
  }, [paymentType]);

  const handleForexExchange = async (values) => {
    const data = {
      from: forexRateRequestData.from,
      to: forexRateRequestData.to,
      accountFromId: values.Payment.FromAccount,
      accountToId: values.Payment.BeneficiaryAccountNumber,
    };

    if (forexRateRequestData.amountFrom) {
      data.amountFrom = forexRateRequestData.amountFrom;
    } else {
      data.amountTo = forexRateRequestData.amountTo;
    }

    const response = await dispatch(AMCActions.postForexDraft(data));
    setSubmitting(false);

    if (response?.payload?.Id) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
    // TODO handle payment preview logic?
  };

  const AddRemoveNIUMPaymentValues = (currentValues) => {
    if (paymentType === Payment.NEW) {
      delete currentValues.Payment.AmountBuy;
      delete currentValues.Payment.AmountSell;
      delete currentValues.Payment.FixRateBuy;
      delete currentValues.Payment.FixRateSell;
      if (!currentValues?.Payment?.Currency) {
        currentValues.Payment.Currency = CURRENCIES.EUR;
      }
      if (currentValues?.Payment?.Currency?.indexOf('_') !== -1) {
        currentValues.Payment.Currency = currentValues.Payment.Currency.split('_')[1];
      }
    }
  };

  const AddRemoveTarget2PaymentValues = (currentValues) => {
    if (paymentType === Payment.NEW) {
      if (
        currentValues.Payment.Currency === CURRENCIES.EUR &&
        ((checkIBANResult?.IsValid &&
          checkIBANResult.Participant &&
          !checkIBANResult.Participant?.SepaInst &&
          !checkIBANResult.Participant?.SepaSCT &&
          !checkIBANResult.Participant?.SepaSDD) ||
          target2Data?.target2)
      ) {
        currentValues.Payment.PaymentSystem = PAYMENT_SYSTEMS.TARGET2;
      }
    }
  };

  const edit = () => {
    setErrors(null);
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleForexSubmitPreview = async () => {
    const response = await dispatch(AMCActions.confirmForexRate(forexDraftInfo.Id));
    if (response?.payload?.Success) {
      setCurrentStep((prevStep) => prevStep + 1);
      Notification({
        type: 'success',
        message: i18.Messages.ForexExchangeIsSuccessfull,
      });
    }
  };

  const submit = async (values) => {
    setErrors(null);
    setSubmitting(true);
    setData(values);

    if (values.Payment.PaymentType === 'forex-exchange-form') {
      handleForexExchange(values);
      return;
    }

    const currentValues = {
      ...values,
      Payment: {
        ...values.Payment,
        IsPurposeSelected: values.Payment.IsPurposeSelected === 'true' ? values.Payment.IsPurposeSelected.toString() : 'false',
        AmountStr: values?.Payment?.AmountStr?.toString()?.replace('.', ',') ?? '',
        PaymentType: values.Payment.PaymentType ? values.Payment.PaymentType.toString() : null,
        Amount: Number(values?.Payment?.AmountStr?.toString()?.replace(',', '.')) ?? 0,
        //PaymentSystem: 'SepaSct',
      },
    };

    if (paymentType === Payment.NEW && values.Payment.IsPurposeSelected === 'false') {
      currentValues.Payment.ReferenceNo = currentValues.Payment.Purpose;
      currentValues.Payment.BeneficiarysCode = '';
      delete currentValues.Payment.Purpose;
      delete currentValues.FullTextSearch;
      delete currentValues.Payment.fromHistory;
    }

    if (paymentType === Payment.INTERNATIONAL) {
      currentValues.Payment.IsPurposeSelected = 'true';
    }

    if (paymentType === Payment.NEW && !currentValues.Payment.IsAdditionalDataSelected) {
      delete currentValues.Payment.PayerType;
      delete currentValues.Payment.PayerName;
      delete currentValues.Payment.PayerIdentTypeValue;
      delete currentValues.Payment.PayerIdentTypeCode;
      delete currentValues.Payment.PayerAgreementIdentTypeCode;
      delete currentValues.Payment.PayerAddress;
      delete currentValues.Payment.InitPayerType;
      delete currentValues.Payment.InitPayerName;
      delete currentValues.Payment.InitPayerIdentTypeCode;
      delete currentValues.Payment.InitPayerIdentTypeValue;
      delete currentValues.Payment.PayeeType;
      delete currentValues.Payment.PayeeIdentTypeCode;
      delete currentValues.Payment.PayeeIdentTypeValue;
      delete currentValues.Payment.PayeeAddress;
      delete currentValues.Payment.FinalPayeeType;
      delete currentValues.Payment.FinalPayeeName;
      delete currentValues.Payment.FinalPayeeIdentTypeCode;
      delete currentValues.Payment.FinalPayeeIdentTypeValue;
      delete currentValues.Payment.SEPAPaymentType;
      delete currentValues.FullTextSearch;
      delete currentValues.Payment.fromHistory;
    }

    AddRemoveNIUMPaymentValues(currentValues);
    AddRemoveTarget2PaymentValues(currentValues);

    if (currentValues.Payment.PayerAddress && currentValues.Payment.PayerAddress !== '') {
      currentValues.Payment.PayerAddressChk = 'true';
    }
    if (currentValues.Payment.PayeeAddress && currentValues.Payment.PayeeAddress !== '') {
      currentValues.Payment.PayeeAddressChk = 'true';
    }

    if (paymentType === Payment.FEE) {
      const activeAccount = MALR.ManagedAccounts.find((x) => x.IsActive === true);
      currentValues.Payment.Purpose = generateFeeCode(values);
      currentValues.FeePayment = JSON.stringify({
        ...values.feePayment,
        feeValues: values.feePaymentData,
        paymentPayerCode: values.Payment.CustomersCode,
      });
      currentValues.Payment = {
        ...currentValues.Payment,
        PaymentType: currentValues.Payment.PaymentType.toString(),
        IsSEPAPaymentTypeSelected: currentValues.Payment.IsSEPAPaymentTypeSelected.toString(),
        FeePayment: currentValues.FeePayment,
        FeeModuleId: currentValues.FeeModuleId,
        InitPayerIdentTypeCode: 'CUST',
        InitPayerIdentTypeValue: values.Payment.CustomersCode,
        InitPayerType: activeAccount?.Type,
        InitPayerName: activeAccount?.Name,
      };

      delete currentValues.feePaymentData;
      delete currentValues.Payment.Template;
      delete currentValues.Payment.FeeProvider;
      delete currentValues.feePayment;
      delete currentValues.FeePayment;
      delete currentValues.Payment.FeeCode;
      delete currentValues.Payment.PayeeBank;
      delete currentValues.note;
    }

    try {
      const response = await dispatch(PMCActions.postPVR(currentValues));
      setSubmitting(false);

      if (response?.payload?.IsSuccessfull) {
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        const errs = response?.payload?.ErrorCaptions;
        const globalErrors = [];
        const errorFields = {};
        const fields = {
          PMC00005: 'IsPurposeSelected',
          PMC00008: 'TemplateName',
          PMC00010: 'PayerIdentTypeValue',
          PMC00011: 'PayerAddress',
          PMC00013: 'PayeeIdentTypeValue',
          PMC00014: 'PayeeAddress',
          PMC00015: 'CustomersCode',
          PMC00016: 'Purpose',
          PMC00017: 'SEPAPaymentType',
          PMC00003: 'BeneficiaryAccountNumber',
        };
        for (let i in errs) {
          const item = errs[i];
          if (checkJsonExist(fields, item.ErrorCode)) {
            errorFields[fields?.item?.ErrorCode] = trb(translationGroups.ErrorCode + item.ErrorCode);
          } else {
            globalErrors.push(item);
          }
        }
        if (currentValues?.Payment?.PaymentSystem === 'INST' && response?.payload?.ErrorCode === 'ChangePaymentSystem') {
          currentValues.Payment.PaymentSystem = 'SepaSct';
          globalErrors.push({ ErrorCode: 'SepaInstError' });
        }
        setErrors(globalErrors);

        return {
          Payment: {
            ...errorFields,
          },
        };
      }
    } catch (error) {
      logError(error);
    }
  };

  let errs = errors ? [...errors] : null;

  const submitPreview = async () => {
    setErrors(null);
    setSubmitting(true);
    setWithError(false);

    if (data.Payment.PaymentType === 'forex-exchange-form') {
      handleForexSubmitPreview();
      return;
    }

    const currentValues = {
      ...data,
      Payment: {
        ...data.Payment,
        FinalPayeeAddress: '',
        FinalPayeeAddressChk: 'false',
        FinalPayeeCountryCode: '',
        AmountStr: data.Payment.AmountStr.toString().replace('.', ','),
        Amount: Number(data?.Payment?.AmountStr?.toString()?.replace(',', '.')) ?? 0,
      },
      feePayment: {
        ...data.feePayment,
        feeValues: data.feePaymentData,
      },
    };

    if (paymentType === Payment.NEW && data?.Payment?.IsPurposeSelected === 'false') {
      currentValues.Payment.ReferenceNo = currentValues.Payment.Purpose;
      delete currentValues.Payment.Purpose;
    }
    if (paymentType === Payment.NEW && !currentValues.Payment.IsAdditionalDataSelected) {
      delete currentValues.Payment.PayerType;
      delete currentValues.Payment.PayerName;
      delete currentValues.Payment.PayerIdentTypeValue;
      delete currentValues.Payment.PayerIdentTypeCode;
      delete currentValues.Payment.PayerAgreementIdentTypeCode;
      delete currentValues.Payment.PayerAddress;
      delete currentValues.Payment.InitPayerType;
      delete currentValues.Payment.InitPayerName;
      delete currentValues.Payment.InitPayerIdentTypeCode;
      delete currentValues.Payment.InitPayerIdentTypeValue;
      delete currentValues.Payment.PayeeType;
      delete currentValues.Payment.PayeeIdentTypeCode;
      delete currentValues.Payment.PayeeIdentTypeValue;
      delete currentValues.Payment.PayeeAddress;
      delete currentValues.Payment.FinalPayeeType;
      delete currentValues.Payment.FinalPayeeName;
      delete currentValues.Payment.FinalPayeeIdentTypeCode;
      delete currentValues.Payment.FinalPayeeIdentTypeValue;
      delete currentValues.Payment.SEPAPaymentType;
    }

    if (paymentType === Payment.INTERNATIONAL) {
      currentValues.Payment.IsPurposeSelected = 'true';
    }

    AddRemoveNIUMPaymentValues(currentValues);
    AddRemoveTarget2PaymentValues(currentValues);

    if (currentValues.Payment.PayerAddress && currentValues.Payment.PayerAddress !== '') {
      currentValues.Payment.PayerAddressChk = 'true';
    }
    if (currentValues.Payment.PayeeAddress && currentValues.Payment.PayeeAddress !== '') {
      currentValues.Payment.PayeeAddressChk = 'true';
    }

    if (paymentType === Payment.FEE) {
      const activeAccount = MALR.ManagedAccounts.find((x) => x.IsActive === true);
      currentValues.Payment.Purpose = generateFeeCode(data);
      currentValues.FeePayment = JSON.stringify({
        ...data.feePayment,
        feeValues: data.feePaymentData,
        paymentPayerCode: data.Payment.CustomersCode,
      });
      currentValues.Payment = {
        ...currentValues.Payment,
        PaymentType: currentValues.Payment.PaymentType.toString(),
        IsSEPAPaymentTypeSelected: currentValues.Payment.IsSEPAPaymentTypeSelected.toString(),
        FeePayment: currentValues.FeePayment,
        FeeModuleId: currentValues.FeeModuleId,
        InitPayerIdentTypeCode: 'CUST',
        InitPayerIdentTypeValue: data.Payment.CustomersCode,
        InitPayerType: activeAccount?.Type,
        InitPayerName: activeAccount?.Name,
      };
      delete currentValues.feePaymentData;
      delete currentValues.Payment.Template;
      delete currentValues.Payment.FeeProvider;
      delete currentValues.feePayment;
      delete currentValues.FeePayment;
      delete currentValues.FeeModuleId;
      delete currentValues.Payment.FeeCode;
      delete currentValues.Payment.PayeeBank;
      delete currentValues.note;
    }

    const response = await dispatch(PMCActions.postAPR({ ...currentValues, Language: language }));
    if (response.payload?.IsSuccessfull) {
      if (response.payload.RQ_ID !== '') {
        setApprove(true);
        setRQID(response.payload.RQ_ID);
        setAuthType(response.payload.AuthType);
        setCode(response.payload.ControlCode);
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    } else {
      setErrors(response?.payload?.PaymentErrors?.filter((items, index) => index === response?.payload?.PaymentErrors.findIndex((item) => item.ErrorCode === items.ErrorCode)));
    }
    setSubmitting(false);
    if (response?.payload?.RQ_ID === '') {
      await Sleep(1000);
      await dispatch(PMCActions.postPTR());
    }
  };

  const cancelApprove = () => {
    setSubmitting(false);
    setApprove(false);
    setRQID(null);
    setAuthType(null);
    setCode(null);
    Notification({
      type: 'warning',
      message: i18.NotificationTitles.PaymentCanceled,
      description: i18.NotificationMessages.PaymentCanceled,
    });
  };

  const successApprove = async () => {
    setApprove(false);
    setCurrentStep((prevStep) => prevStep + 1);
    setWithError(false);
  };

  const waitingApprove = async () => {
    setApprove(false);
    setWithError('waiting');
    setCurrentStep((prevStep) => prevStep + 1);
    Notification({
      type: 'info',
      message: i18.NotificationTitles.NeedCheckLater,
      description: i18.NotificationMessages.NeedCheckLater,
    });
  };

  const unsuccessApprove = async (err) => {
    setApprove(false);
    setWithError(err);
    setCurrentStep((prevStep) => prevStep + 1);
    Notification({
      type: 'error',
      message: i18.NotificationTitles.IsUnsuccessfull,
      description: translationGroups.ErrorCode + err,
    });
  };

  const BadApproveCode = () => {
    setSubmitting(false);
    setApprove(false);
    setRQID(null);
    setAuthType(null);
    setCode(null);
    Notification({
      type: 'error',
      message: i18.NotificationTitles.BadApproveCode,
      description: i18.NotificationMessages.BadApproveCode,
    });
  };

  const download = () => {
    console.log('download');
  };

  const feePaymentHistory = (values) => {
    if (paymentType !== Payment.FEE) {
      return;
    }

    if (data?.Payment?.CustomersCode === values?.Payment?.CustomersCode) {
      return;
    }
    const feePaymentData = values?.feePayment?.feeModule?.rows;
    if (!feePaymentData && feePaymentData.length === 0) {
      return;
    }

    const findArr = feePaymentData.find((x) => x.fields.find((y) => y.id === 'I' && y.selected === true) !== undefined);
    if (!findArr) {
      return;
    }
    if (!values?.Payment?.CustomersCode) {
      setData({ ...values, feePaymentData: [] });
      return null;
    }

    const dt = {
      InitPayerIdentTypeValue: values?.Payment?.CustomersCode,
      FeeModuleId: values?.FeeModuleId,
    };
    dispatch(FeeActions.postLastFee(dt)).then((response) => {
      if (response.type !== FeeConstants.POST_FEE_LAST_FEE_SUCCESS) {
        return null;
      }
      const dt = response?.payload;
      if (!dt?.IsSuccessfull) {
        setData({ ...values, feePaymentData: [] });
        return null;
      }
      if (!dt?.FeePayment) {
        setData({ ...values, feePaymentData: [] });
        return null;
      }
      const feeData = dt.FeePayment;

      const feeValues = feeData?.feeValues;
      if (!feeValues) {
        return null;
      }

      const feeValuesMapped = feeValues /*.filter(x => x.I)*/
        .map((item) => {
          if (!item) {
            return {};
          }
          if (item.I && item.TA) {
            return { N: item.I, TA: item.TA };
          }
          if (item.I && item.A) {
            return { N: item.I, A: item.A };
          }
          if (item.I) {
            return { N: item.I };
          }
          if (item.A) {
            return { A: item.A };
          }
          return {};
        });

      setData({ ...values, feePaymentData: feeValuesMapped });
    });
  };

  const resetPayment = async () => {
    dispatch(PMCActions.postPTR());
    setSubmitting(false);
    setCurrentStep(0);
    setData(null);

    setApprove(false);
    setErrors(null);
    setSaveSign(false);
    setDecorator(
      FormDecoratorSwitch({
        name: paymentType,
      })
    );
    localStorage.removeItem('SavedIBAN');
    localStorage.removeItem('SavedCurrency');
    await dispatch(PMCActions.clearCheckIBANMember());
    await dispatch(PMCActions.clearHistoricalPayments());
    await dispatch(PMCActions.clearPVR());
  };

  const paymentSaveToSign = () => {
    setErrors(null);
    setSubmitting(true);

    const currentValues = {
      ...data,
      Payment: {
        ...data.Payment,
        FinalPayeeAddress: '',
        FinalPayeeAddressChk: 'false',
        FinalPayeeCountryCode: '',
        AmountStr: data.Payment.AmountStr.toString().replace('.', ','),
        Amount: Number(data.Payment.AmountStr.toString().replace(',', '.')),
      },
      feePayment: {
        ...data.feePayment,
        feeValues: data.feePaymentData,
      },
    };

    if (paymentType === Payment.NEW && data.Payment.IsPurposeSelected === 'false') {
      currentValues.Payment.ReferenceNo = currentValues.Payment.Purpose;
      delete currentValues.Payment.Purpose;
    }
    if (paymentType === Payment.NEW && !currentValues.Payment.IsAdditionalDataSelected) {
      AddRemoveTarget2PaymentValues(currentValues);

      delete currentValues.Payment.PayerType;
      delete currentValues.Payment.PayerName;
      delete currentValues.Payment.PayerIdentTypeValue;
      delete currentValues.Payment.PayerIdentTypeCode;
      delete currentValues.Payment.PayerAgreementIdentTypeCode;
      delete currentValues.Payment.PayerAddress;
      delete currentValues.Payment.InitPayerType;
      delete currentValues.Payment.InitPayerName;
      delete currentValues.Payment.InitPayerIdentTypeCode;
      delete currentValues.Payment.InitPayerIdentTypeValue;
      delete currentValues.Payment.PayeeType;
      delete currentValues.Payment.PayeeIdentTypeCode;
      delete currentValues.Payment.PayeeIdentTypeValue;
      delete currentValues.Payment.PayeeAddress;
      delete currentValues.Payment.FinalPayeeType;
      delete currentValues.Payment.FinalPayeeName;
      delete currentValues.Payment.FinalPayeeIdentTypeCode;
      delete currentValues.Payment.FinalPayeeIdentTypeValue;
      delete currentValues.Payment.SEPAPaymentType;
    }
    if (currentValues.Payment.PayerAddress && currentValues.Payment.PayerAddress !== '') {
      currentValues.Payment.PayerAddressChk = 'true';
    }
    if (currentValues.Payment.PayeeAddress && currentValues.Payment.PayeeAddress !== '') {
      currentValues.Payment.PayeeAddressChk = 'true';
    }

    if (paymentType === Payment.FEE) {
      const activeAccount = MALR.ManagedAccounts.find((x) => x.IsActive === true);
      currentValues.Payment.Purpose = generateFeeCode(data);
      currentValues.FeePayment = JSON.stringify({
        ...data.feePayment,
        feeValues: data.feePaymentData,
        paymentPayerCode: data.Payment.CustomersCode,
      });
      currentValues.Payment = {
        ...currentValues.Payment,
        PaymentType: currentValues.Payment.PaymentType.toString(),
        IsSEPAPaymentTypeSelected: currentValues.Payment.IsSEPAPaymentTypeSelected.toString(),
        FeePayment: currentValues.FeePayment,
        FeeModuleId: currentValues.FeeModuleId,
        InitPayerIdentTypeCode: 'CUST',
        InitPayerIdentTypeValue: data.Payment.CustomersCode,
        InitPayerType: activeAccount?.Type,
        InitPayerName: activeAccount?.Name,
      };

      delete currentValues.feePaymentData;
      delete currentValues.Payment.Template;
      delete currentValues.Payment.FeeProvider;
      delete currentValues.feePayment;
      delete currentValues.FeePayment;
      delete currentValues.FeeModuleId;
      delete currentValues.Payment.FeeCode;
      delete currentValues.Payment.PayeeBank;
      delete currentValues.note;
    }

    dispatch(PMCActions.postSPR(currentValues)).then((response) => {
      if (response.payload.IsSuccessfull) {
        setSubmitting(false);
        setSaveSign(true);
      } else {
        setErrors(response?.payload?.ErrorCaptions || null);
        setSubmitting(false);
      }
    });
  };

  const submitSmsApprove = (values) => {
    dispatch(PMCActions.postAPSMSR(values)).then((response) => {
      if (response.payload.IsSuccessfull === true) successApprove();
      else if (response.payload.IsSuccessfull === false && response.payload.ErrorCode && response.payload.ErrorCode.toLowerCase() === 'waiting_to_confirm') waitingApprove();
      else if (response.payload.IsSuccessfull === false && response.payload.ErrorCode) unsuccessApprove(response.payload.ErrorCode);
      else BadApproveCode();
    });
  };

  const submitMobileApprove = async () => {
    try {
      const response = await dispatch(PMCActions.postAPESCR({ RQ_ID: RQ_ID }));
      if (response?.payload) {
        if (response.payload.AuthCheckResult === null) {
          return true;
        }
        if (response.payload.AuthCheckResult === false) {
          if (response.payload.IsSuccessfull === false && response.payload.ErrorCode && response.payload.ErrorCode.toLowerCase() === 'waiting_to_confirm') waitingApprove();
          else if (response.payload.ErrorCode) unsuccessApprove(response.payload.ErrorCode);
          else BadApproveCode();
        } else if (response.payload.AuthCheckResult === true && response.payload.IsSuccessfull) {
          successApprove();
        }
      } else {
        throwError(response, 'submitMobileApprove');
      }
      return false;
    } catch (error) {
      logError(error);
      return false;
    }
  };

  const onFullTextSearch = (value) => {
    const trimmedValue = value.trim(),
      iban = localStorage.getItem('SavedIBAN');

    if (lastFullTextSearch === trimmedValue) {
      return;
    }
    lastFullTextSearch = trimmedValue;
    if (timeoutFullTextSearch) {
      clearTimeout(timeoutFullTextSearch);
    }

    const currency = localStorage.getItem('SavedCurrency');
    if ((iban === 'null' || !iban) && (!currency || currency === CURRENCIES.EUR)) {
      timeoutFullTextSearch = setTimeout(() => fullTextSearch(trimmedValue), 500);
    }
  };

  const fullTextSearch = (value) => {
    const ActiveAccount = MALR?.ManagedAccounts != null ? MALR.ManagedAccounts.find((x) => x.IsActive === true) : null;

    if (value && value.length > 4 && value.length < 10 && ActiveAccount && ActiveAccount.ID) {
      dispatch(
        PMCActions.getHistoricalPayments({
          ClientId: ActiveAccount.ID,
          SearchText: value,
        })
      );
    }
  };

  if ((sendPTR && currentStep !== 2) || sendFeeList || sendMALR || sendPayPersonTypes) {
    return <ContentLoader />;
  }

  if ((!PTR && currentStep !== 2) || !MALR) {
    return <ContentNoData id="PaymentPTRMALR" />;
  }

  if (paymentType === Payment.FEE && !feeList) {
    return <ContentNoData id="PaymentFeeList" />;
  }

  const user = MALR.ManagedAccounts.find((x) => x.IsActive === true);

  const getFormatedForexDrafrInfo = () => {
    const dt = { ...forexDraftInfo };
    if (dt.accountFromId) {
      dt.IBANAccountFrom = forexPayments?.InitialAccounts?.find((el) => el.AccountID === dt.accountFromId)?.AccountNumber;
    }
    if (dt.accountToId) {
      dt.IBANAccountTo = forexPayments?.DestinationAccounts?.find((el) => el.AccountID === dt.accountToId)?.AccountNumber;
    }
    return dt;
  };

  return (
    <>
      <div className="eb_content_subtitle">{trb(i18.ContentTitles.FeePayment)}</div>
      <Container
        wide
        gradient={currentStep === 2}
      >
        <Steps
          direction="horizontal"
          current={currentStep}
          className={`mb-3 mb-md-5 eb_steps`}
        >
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentFirstStep"
              />
            }
          />
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentSecondStep"
              />
            }
          />
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentThirdStep"
              />
            }
          />
        </Steps>
        <div className="position-relative w-100">
          {currentStep === 0 && (
            <div>
              {(sendCheckIBAN || sendGetFxRate) && <ContentLoader hover />}
              <PaymentFill
                paymentType={Payment.FEE}
                data={data}
                user={user}
                decorator={decorator}
                PTR={PTR}
                PVR={PVR}
                forexPayments={forexPayments}
                PayPersonTypes={PayPersonTypes}
                submit={submitting ? () => {} : submit}
                submitting={submitting}
                errors={errs}
                fees={feeList}
                templateId={props?.location?.state?.templateId}
                feePaymentHistory={feePaymentHistory}
                historicalPayments={historicalPayments}
                onFullTextSearch={onFullTextSearch}
                checkIBANResult={checkIBANResult}
                checkIBANLength={checkIBANLength}
                checkIBANValid={checkIBANValid}
                sendCheckIBAN={sendCheckIBAN}
                submitDisabled={false}
                clearErrors={() => setErrors(null)}
                resetPayment={resetPayment}
                contractDesc2={amcReducers}
              />
            </div>
          )}
          {currentStep === 1 && (
            <PaymentPreview
              paymentType={Payment.FEE}
              data={data}
              PTR={PTR}
              PVR={PVR}
              PayPersonTypes={PayPersonTypes}
              edit={edit}
              submit={submitting ? () => {} : submitPreview}
              submitting={submitting}
              approve={approve}
              AuthType={AuthType}
              RQ_ID={RQ_ID}
              Code={Code}
              cancelApprove={cancelApprove}
              successApprove={successApprove}
              submitSmsApprove={submitSmsApprove}
              submitMobileApprove={submitMobileApprove}
              paymentSaveToSign={paymentSaveToSign}
              errors={errors}
              saveSign={saveSign}
              resetPayment={resetPayment}
              forexDraftInfo={getFormatedForexDrafrInfo()}
            />
          )}
          {currentStep === 2 && (
            <>
              {withError && (
                <>
                  {withError === 'waiting' && <PaymentWaiting resetPayment={resetPayment} />}
                  {withError !== 'waiting' && (
                    <PaymentUnsuccess
                      resetPayment={resetPayment}
                      error={withError}
                    />
                  )}
                </>
              )}
              {!withError && (
                <PaymentPreview
                  paymentType={Payment.FEE}
                  data={data}
                  PTR={PTR}
                  PVR={PVR}
                  PayPersonTypes={PayPersonTypes}
                  edit={edit}
                  submit={submitting ? () => {} : submitPreview}
                  submitting={submitting}
                  success
                  download={download}
                  resetPayment={resetPayment}
                  forexDraftInfo={getFormatedForexDrafrInfo()}
                />
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
};
