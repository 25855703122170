/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import { i18 } from 'src/Utilities';

import { AMCActions, AMCConstants, PMCActions } from 'src/Redux';
import { UserLimitsActions } from './UserLimitsActions';
import { ContentLoader, Notification, OperationApproval } from 'src/Components';
import { ILimitsTransformed, ITransformedLimitsData, IUserLimitsGrantLimit } from './interfaces';
import { trb } from 'src/Utilities';
import { AccountsLimits } from './AccountsLimits';
import { isEqual } from 'lodash';

interface ISigningInfo {
  approve: boolean;
  RQ_ID: string;
  AuthType: string;
  Code: string;
}

interface IProps {
  userId: string;
  isMyself: boolean;
  GrantLimits: IUserLimitsGrantLimit;
}

export const UserAccountsLimits = ({ GrantLimits, userId, isMyself }: IProps) => {
  const dispatch = useDispatch();

  const { updatingUserLimits } = useSelector((state: any) => state.AMCReducers);

  const { MALR } = useSelector((state: any) => state.UACReducers);
  const { language } = useSelector((state: any) => state.languageReducer);
  const [edit, setEdit] = useState(false);
  const [signingInfo, setSigningInfo] = useState<ISigningInfo | null>(null);
  const [checkSubmitStatusProgress, setCheckSubmitStatusProgress] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const timerRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    timerRef.signingInfo = signingInfo;
  }, [signingInfo]);

  const checkSubmitStatus = async (grantId: string) => {
    if (!(timerRef as any)?.signingInfo) {
      setCheckSubmitStatusProgress(false);
      return;
    }
    setCheckSubmitStatusProgress(true);
    const { error, type } = await dispatch((AMCActions as any).getUpdatingLimitStatus(grantId));
    if ((timerRef as any).signingInfo) {
      if (error?.status === 204 || error?.code === 'ERR_NETWORK') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        timerRef.current = setTimeout(() => checkSubmitStatus(grantId), 1000);
      } else if (type === AMCConstants.GET_UPDATING_LIMIT_STATUS_SUCCESS) {
        setEdit(false);
        setCheckSubmitStatusProgress(false);
        dispatch((AMCActions as any).getClientLimits(userId, MALR.ID === userId));
        Notification({
          type: 'success',
          message: i18.NotificationTitles.LimitsChanged,
          description: i18.NotificationMessages.LimitsChangedSuccessfully,
        });
      }
    } else {
      setCheckSubmitStatusProgress(false);
    }
  };

  const submitNewLimits = async (values: ITransformedLimitsData) => {
    const content = {
      Language: language,
      EbankGrantId: values.EbankGrantId,
      GrantedClientId: `${userId}`,
      AccountLimits: values.Limits.map(({ AccountId, Permission, Limits }: ILimitsTransformed) => ({
        AccountId,
        Permission,
        Limits: Limits.map(({ Currency, SMSOperationLimit, SMSDayLimit, SMSMonthLimit, MSignOperationLimit, MSignDayLimit, MSignMonthLimit }) => ({
          Currency,
          SMSOperationLimit,
          SMSDayLimit,
          SMSMonthLimit,
          MSignOperationLimit,
          MSignDayLimit,
          MSignMonthLimit,
        })),
      })),
    };
    const response = await dispatch((AMCActions as any).postClientLimits({ ...content }));
    const { payload, type } = response;
    const ErrorCode = payload?.ErrorCode;
    if (type === AMCConstants.POST_USER_LIMITS_ERROR) {
      cancelApprove('');
    } else if (ErrorCode) {
      cancelApprove(ErrorCode);
    } else if (payload) {
      setSigningInfo({
        approve: true,
        RQ_ID: payload.RQ_ID,
        AuthType: payload.AuthType,
        Code: payload.ControlCode,
      });
    }
  };

  const clickChangeLimits = (form: any) => {
    if (form) {
      form.reset();
    }
    setEdit((p) => !p);
  };

  const cancelApprove = (ErrorCode: string) => {
    let message = ErrorCode;
    if (message) {
      message = message?.replace?.('.', '');
    }
    setSigningInfo(null);
    clearTimeout(timerRef.current);

    Notification({
      type: 'error',
      // @ts-ignore
      message: message ? i18.NotificationTitles[message] : i18.NotificationTitles?.LimitsChangeFail,
      // @ts-ignore
      description: message ? i18.NotificationMessages[message] : i18.NotificationMessages?.LimitsChangeFailed,
    });
  };

  const successApprove = async () => {
    checkSubmitStatus(GrantLimits.EbankGrantId);
  };

  const submitSmsApprove = async (values: any) => {
    const { payload } = await dispatch((PMCActions as any).postAPSMSR(values));
    const { IsSuccessfull, ErrorCode } = payload ?? {};
    if (IsSuccessfull) {
      checkSubmitStatus(GrantLimits.EbankGrantId);
    } else {
      cancelApprove(ErrorCode);
    }
  };

  const submitMobileApprove = async () => {
    const { payload } = await dispatch((PMCActions as any).postAPESCR({ RQ_ID: signingInfo?.RQ_ID }));
    const { AuthCheckResult, IsSuccessfull, ErrorCode } = payload ?? {};

    if (AuthCheckResult === null) {
      return true;
    }
    if (AuthCheckResult === false) {
      cancelApprove(ErrorCode);
    } else if (AuthCheckResult === true && IsSuccessfull) {
      successApprove();
    }
    return false;
  };

  return (
    <Form
      onSubmit={submitNewLimits}
      initialValues={{ Limits: GrantLimits.LimitsModelLists, EbankGrantId: GrantLimits.EbankGrantId, ProfileType: GrantLimits.ProfileType }}
      render={({ handleSubmit, form, values }) => {
        return (
          <form
            className="mb-3 w-100 user-limits-form"
            onSubmit={handleSubmit}
          >
            <>
              <AccountsLimits
                data={values}
                edit={edit}
                disabledForm={!!signingInfo}
                isAdmin={isMyself || GrantLimits.IsAdmin}
                userId={userId}
              />

              {(!GrantLimits.IsAdmin && isMyself) || checkSubmitStatusProgress || signingInfo ? null : (
                <UserLimitsActions
                  handleChangeLimits={() => clickChangeLimits(form)}
                  submittingNewLimits={updatingUserLimits}
                  edit={edit}
                  isEqual={edit && isEqual({ Limits: GrantLimits.LimitsModelLists, EbankGrantId: GrantLimits.EbankGrantId, ProfileType: GrantLimits.ProfileType }, values)}
                />
              )}
              {signingInfo && !checkSubmitStatusProgress && (
                <OperationApproval
                  RQ_ID={signingInfo.RQ_ID}
                  AuthType={signingInfo.AuthType}
                  code={signingInfo.Code}
                  cancelApprove={cancelApprove}
                  successApprove={successApprove}
                  submitSmsApprove={submitSmsApprove}
                  submitMobileApprove={submitMobileApprove}
                  dontScrollToTop={true}
                />
              )}
              {checkSubmitStatusProgress && <ContentLoader title={trb(i18.Labels.SavingLimits)} />}
            </>
          </form>
        );
      }}
    />
  );
};
