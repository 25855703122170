import { Col, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IPaymentError } from 'src/Containers/Payments/components';
import { PAYMENT_ERRORS, PAYMENT_SYSTEMS, i18, removeWhitespaces, translationGroups, trb } from 'src/Utilities';
import { BICS_SET_1 } from 'src/constants';
import { FormField, ToggleButton } from 'src/shared';

interface IProps {
  errorsPVR?: Array<IPaymentError> | null;
}

export const FieldPaymentSystem = ({ errorsPVR }: IProps) => {
  const { checkIBANResult } = useSelector((state: any) => state.PMCReducers);
  const { setFieldValue, values }: FormikContextType<any> = useFormikContext<any>();
  const instDisabled =
    BICS_SET_1.includes(values?.Payment?.BIC) ||
    !values?.Payment?.BeneficiaryAccountNumber ||
    removeWhitespaces(values.Payment.BeneficiaryAccountNumber) !== removeWhitespaces(checkIBANResult?.IBAN) ||
    (removeWhitespaces(values.Payment.BeneficiaryAccountNumber) === removeWhitespaces(checkIBANResult?.IBAN) && !checkIBANResult?.Participant?.SepaInst) ||
    !!errorsPVR?.find((el) => el.ErrorCode === PAYMENT_ERRORS.SEPA_INST);

  useEffect(() => {
    setFieldValue('Payment.PaymentSystem', instDisabled ? PAYMENT_SYSTEMS.SEPA : values?.Payment?.PaymentSystem ?? PAYMENT_SYSTEMS.SEPA);
  }, [values?.Payment?.BeneficiaryAccountNumber]);

  if (values.Payment.PaymentFormType === 'template') {
    return null;
  }

  return (
    <Row align="middle">
      <Col
        className="my-1"
        span={24}
        xl={10}
      >
        {trb(i18.Labels.PaymentType)}
      </Col>
      <Col
        span={24}
        xl={14}
      >
        <FormField
          name="Payment.PaymentSystem"
          floating={false}
        >
          <ToggleButton
            options={[
              {
                label: trb(translationGroups.PaymentTypeSepa + 'SCT'),
                value: 'SepaSct',
              },
              {
                label: trb(translationGroups.PaymentTypeSepa + 'Inst'),
                value: 'INST',
                disabled: instDisabled,
              },
            ]}
          />
        </FormField>
      </Col>
    </Row>
  );
};

